import React, { useState } from "react";
import "./subjectWiseReport.css";

const SubjectWiseReport = (props) => {
  const [subcount, setsubcount] = useState(2);

  const TotalAttemptTime = (totaltime) => {
    let min = Math.floor(totaltime / 60000) + " m";
    let sec = ((totaltime % 60000) / 1000).toFixed(0) + " s";
    if (min > 0) {
      return min;
    } else {
      return sec;
    }
  };

  return (
    <>
      <p> in Subject Wise Report </p>
      {/* <div className={subcount === props.subjectWiseDistribution.length == 0 ? "maindivfirst" : "maindiv"}>
            {
                props.subjectWiseDistribution && props.subjectWiseDistribution.map((subject, index) => (
                    <div className="maincard">
                        <div style={{ width: "100%", marginTop: "5px", textAlign: "center" }}>
                            <span className="subjectlable">
                            {subject.subject_name}
                            </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "15px" }}>
                            <span className="lableName">
                                Marks
                            </span>
                            <span className="valueText">
                            {subject.total_score}/{subject.maximum_marks}
                            </span>
                        </div>
                        <div style={{ border: "1px dashed  #D7D5EA", margin: '15px' }}></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "15px" }}>
                            <span className="lableName">
                                Correct Answer
                            </span>
                            <span className="valueText">
                            {subject.right_ques_count}
                            </span>
                        </div>
                        <div style={{ border: "1px dashed  #D7D5EA", margin: '15px' }}></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "15px" }}>
                            <span className="lableName">
                                Incorrect Answer
                            </span>
                            <span className="valueText">
                            {subject.wrong_ques_count}
                            </span>
                        </div>
                        <div style={{ border: "1px dashed  #D7D5EA", margin: '15px' }}></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "15px" }}>
                            <span className="lableName">
                                Positive Marks
                            </span>
                            <span className="valueText">
                            {subject.right_ques_marks}
                            </span>
                        </div>
                        <div style={{ border: "1px dashed  #D7D5EA", margin: '15px' }}></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "15px" }}>
                            <span className="lableName">
                                Negative Marks
                            </span>
                            <span className="valueText">
                            {subject.wrong_ques_marks}
                            </span>
                        </div>
                        <div style={{ border: "1px dashed  #D7D5EA", margin: '15px' }}></div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: "15px" }}>
                            <span className="lableName">
                                Time Taken
                            </span>
                            <span className="valueText">
                               {TotalAttemptTime(subject.total_attempted_time)}
                            </span>
                        </div>
                        <div style={{ border: "1px dashed  #D7D5EA", margin: '15px' }}></div>
                    </div>
                ))
            }
        </div> */}
    </>
  );
};
export default SubjectWiseReport;
