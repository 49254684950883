import React, { useState, useEffect } from "react";
import "./subjectwiselegends.css";
import notvisited from "../../../assets/icons/not-visited.png";
import notanswered from "../../../assets/icons/not-answered.png";
import answered from "../../../assets/icons/answered.png";
import notanswerreview from "../../../assets/icons/not-answered-review.png";
import answerreview from "../../../assets/icons/answered-review.png";
import { connect } from "react-redux";
import saveAndNext from "../../../services/saveAndNext";

const SubjectWiseLegendsCount = (props) => {
  const [images, changeImages] = useState(notvisited);
  const [indexNumber, setIndexNumber] = useState(props.quesIndex);

  useEffect(() => {
    if (props.questionstatus) {
      let permeter = props.questionstatus.find(
        ({ ques_id }) => ques_id == props.item.ques_id
      );
      changeImg(permeter);
    }
  }, []);

  useEffect(() => {
    if (
      props?.item.ques_id ==
      parseInt(
        props.questionstatus &&
          Object.keys(props.questionstatus[props.activeQuesInd]).length > 0
          ? props.questionstatus[props.activeQuesInd]?.ques_id
          : 0
      )
    ) {
      const element = document.getElementById(indexNumber);
      element.scrollIntoView(true);
      changeImg(props.questionstatus[props.activeQuesInd]);
    }
  }, [props.questionstatus]);

  const changeImg = (QuestionNum) => {
    if (QuestionNum.status_number == 1) {
      changeImages(notvisited);
    } else if (QuestionNum.status_number == 2) {
      changeImages(notanswered);
    } else if (QuestionNum.status_number == 3) {
      changeImages(notanswerreview);
    } else if (QuestionNum.status_number == 4) {
      changeImages(answered);
    } else if (QuestionNum.status_number == 5) {
      changeImages(answerreview);
    }
  };

  const onClickQuestNum = () => {
    if (
      props?.test_data?.is_neet_test == 1 &&
      props?.test_data?.is_sec_time == 1
    ) {
      if (
        "" +
          props?.ques_data[indexNumber - 1]?.subject_id +
          "#" +
          props?.ques_data[indexNumber - 1]?.section_id ==
        props?.selectedSection
      ) {
        Promise.resolve(
          props.dispatch({
            type: "ACTIVE_QUESTION_NUMBER",
            activeQuesInd: indexNumber - 1,
          })
        ).then(() => {
          let questionStatus = props.questionstatus;
          if (questionStatus[indexNumber - 1].status_number == 1) {
            questionStatus[indexNumber - 1].status_number = 2;
            props.dispatch({
              type: "CHANGES_STATUS",
              questionstatus: [...questionStatus],
            });
          }
          saveAndNext(props);
        });
      }
    } else {
      Promise.resolve(
        props.dispatch({
          type: "ACTIVE_QUESTION_NUMBER",
          activeQuesInd: indexNumber - 1,
        })
      ).then(() => {
        let questionStatus = props.questionstatus;
        if (questionStatus[indexNumber - 1].status_number == 1) {
          questionStatus[indexNumber - 1].status_number = 2;
          props.dispatch({
            type: "CHANGES_STATUS",
            questionstatus: [...questionStatus],
          });
        }
        saveAndNext(props);
      });
    }
  };

  return (
    <>
      <div
        id={indexNumber}
        className="bell"
        onClick={() => {
          if (props.test_settings.is_allow_back_button) {
            onClickQuestNum();
          }
        }}
      >
        {<img className="legendscountImg" src={images} />}

        <div className="bellnumbers">
          <span>{indexNumber}</span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  test_settings: state.test_settings,
  questionstatus: state.questionstatus,
  activeQuesInd: state.activeQuesInd,
  ques_data: state.ques_data,
  selectedSection: state?.selectedSection,
  test_data: state?.test_data,
});
export default connect(mapStateToProps)(React.memo(SubjectWiseLegendsCount));
