import "./styles.scss";
import { useState, useRef } from "react";
import leftIcon from "../../assets/icons/leftIcon.svg";
import rightIcon from "../../assets/icons/rightIcon.svg";
import rightIconLight from "../../assets/icons/rightLight.svg";
import leftLightIcon from "../../assets/icons/leftIconLight.svg";

export default function Slider(props) {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <div class="w-357">
      {scrollX !== 0 && (
        <button
          className="prev"
          style={{ opacity: "1" }}
          onClick={() => slide(-50)}
        >
          <img height="10px" width="10px" src={leftIcon} />
        </button>
      )}
      {scrollX == 0 && (
        <button className="prev">
          <img height="10px" width="10px" src={leftLightIcon} />
        </button>
      )}
      {props?.is_neet_test == 1 && props?.is_sec_time == 1 ? (
        <ul className="uldiv" ref={scrl} onScroll={scrollCheck}>
          {props.subjects_data &&
            props.subjects_data.map((item, index) => (
              <>
                {props.subjectid ==
                  "" + item.subject_id + "#" + item.section_id && (
                  <li class="lidivSelected">
                    <p className="lidivSelectedSpan">
                      {item.subject_name}{" "}
                      {item.hasOwnProperty("section_title") && "-"}{" "}
                      {item?.section_title}
                    </p>
                  </li>
                )}
                {props.subjectid !=
                  "" + item.subject_id + "#" + item.section_id && (
                  <li class="lidiv">
                    {item.subject_name}{" "}
                    {item.hasOwnProperty("section_title") && "-"}{" "}
                    {item.section_title}
                  </li>
                )}
              </>
            ))}
        </ul>
      ) : props?.is_neet_test == 1 && props?.is_sec_time == 0 ? (
        <ul className="uldiv" ref={scrl} onScroll={scrollCheck}>
          {props.subjects_data &&
            props.subjects_data.map((item, index) => (
              <>
                {props.subjectid ==
                  "" + item.subject_id + "#" + item.section_id && (
                  <li class="lidivSelected">
                    <p className="lidivSelectedSpan">
                      {item.subject_name}{" "}
                      {item.hasOwnProperty("section_title") && "-"}{" "}
                      {item?.section_title}
                    </p>
                  </li>
                )}
                {props.subjectid !=
                  "" + item.subject_id + "#" + item.section_id && (
                  <li
                    class="lidiv"
                    onClick={() => {
                      props.setSubjectId(
                        "" + item.subject_id + "#" + item.section_id
                      );
                      props.dispatch({
                        type: "SELECTED_SUBJECT",
                        selectedSection:
                          "" + item.subject_id + "#" + item.section_id,
                      });
                    }}
                  >
                    {item.subject_name}{" "}
                    {item.hasOwnProperty("section_title") && "-"}{" "}
                    {item.section_title}
                  </li>
                )}
              </>
            ))}
        </ul>
      ) : (
        <ul className="uldiv" ref={scrl} onScroll={scrollCheck}>
          {props.subjects_data &&
            props.subjects_data.map((item, index) => (
              <>
                {props.subjectid == item.subject_id && (
                  <li class="lidivSelected">
                    <p className="lidivSelectedSpan">{item.subject_name}</p>
                  </li>
                )}
                {props.subjectid != item.subject_id && (
                  <li
                    class="lidiv"
                    onClick={() => {
                      props.setSubjectId(item.subject_id);
                    }}
                  >
                    {item.subject_name}
                  </li>
                )}
              </>
            ))}
        </ul>
      )}
      {!scrolEnd && (
        <button className="next" onClick={() => slide(+50)}>
          <img height="10px" width="10px" src={rightIcon} />
        </button>
      )}
      {scrolEnd && (
        <button className="next">
          <img height="10px" width="10px" src={rightIconLight} />
        </button>
      )}
    </div>
  );
}
