import React from "react";
import "./QuestionWiseReport.css";
import Unattempted from "../../../assets/icons/unattempt.png";
import TrueIcon from "../../../assets/icons/true.png";

const QuestionWiseReport = () => {
  return (
    <>
      <div className="containerQuestion">
        <div className="Questiondiv">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span>Q1</span>
            <div style={{ alignItems: "center" }}>
              <strong style={{ color: "orange", marginRight: "10px" }}>
                Unattempted
              </strong>
              <img height="30px" width="30px" src={Unattempted} />
            </div>
          </div>
          <span className="QuestionText">What is a Container? - Docker</span>
          <div className="OptionDiv">
            <div className="GreenLablecount">
              <strong
                style={{ color: "white", fontSize: "12px", fontWeight: 600 }}
              >
                A
              </strong>
            </div>
            <span className="CurrectOption">
              Defines a 3D ridged border. The effect depends on the border-color
              value
            </span>
          </div>
          <div className="OptionDiv">
            <div className="BlackLablecount">
              <strong
                style={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: 600,
                  marginTop: "-1px",
                }}
              >
                B
              </strong>
            </div>
            <span className="NormalOPtion">
              Defines a 3D ridged border. The effect depends on the border-color
              value
            </span>
          </div>
          <div className="OptionDiv">
            <div className="RedLablecount">
              <strong
                style={{ color: "white", fontSize: "12px", fontWeight: 600 }}
              >
                C
              </strong>
            </div>
            <span className="WrongOption">
              Defines a 3D ridged border. The effect depends on the border-color
              value
            </span>
          </div>
          <div className="OptionDiv">
            <div className="BlackLablecount">
              <span
                style={{ color: "white", fontSize: "12px", fontWeight: 600 }}
              >
                D
              </span>
            </div>
            <span className="NormalOPtion">
              Defines a 3D ridged border. The effect depends on the border-color
              value
            </span>
          </div>
          <div
            style={{
              width: "100%",
              border: "1px thin #EDEAEA",
              marginTop: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: "15px",
            }}
          >
            <div className="YourAnswer">
              <span>NA</span>
              <span>Your Answer</span>
            </div>
            <div className="CurrectAnswer">
              <span>B</span>
              <span>Correct Answer</span>
            </div>
            <div className="MarksDiv">
              <span>0.000</span>
              <span>Marks</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuestionWiseReport;
