import React, { useEffect } from "react";
import { Chart } from "chart.js";
const AttemptAnalysis = (props) => {
  useEffect(() => {
    subWiseLine();
  }, []);
  const subWiseLine = () => {
    props.AdvReportData.subject_wise_comparison.forEach((sub, index) => {
      let linelabels = [];
      let linedata = [];
      var str = [];
      sub.tests_data.forEach((test, index) => {
        let test_name = "";
        if (test.test_name.length > 15) {
          test_name = test.test_name.substring(0, 15) + "...";
        } else {
          test_name = test.test_name;
        }
        linelabels.push(test_name);
        linedata.push((test.marks_obtained / test.maximum_marks) * 100);
        str.push(test.marks_obtained + " / " + test.maximum_marks);
      });
      var id = "subWiseLine-" + index;
      var nid = "no-subWiseLine-data-" + index;
      var canvas = document?.getElementById(id);
      var ctx = canvas?.getContext("2d");
      /* var ctx = document.getElementById("canvas-"+index).getContext("2d"); */
      var myLine = new Chart(ctx, {
        type: "line",
        data: {
          labels: linelabels,
          datasets: [
            {
              label: sub.subject_name,
              backgroundColor: "#2ecc71",
              borderColor: "#2ecc71",
              data: linedata,
              fill: false,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          hover: {
            mode: "index",
            intersect: false,
          },
          tooltips: {
            // mode: 'label',
            callbacks: {
              title: function (t, d) {
                return d.labels[t[0].index];
              },
              beforeFooter: function (tooltipItems, data) {
                return str[tooltipItems[0].index];
              },
              footer: function (tooltipItems, data) {
                return "Marks Obtained";
              },
            },
          },

          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Tests",
                },
                ticks: {
                  callback: function (value) {
                    var str = value.substr(0, 10);
                    return str + "..."; //truncate
                  },
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Marks Obtained in (%)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          animation: {
            onComplete: function (animation) {
              var firstSet =
                animation.chart.config.data.datasets[0].data.length;

              if (firstSet < 2) {
                document.getElementById(nid).style.display = "flex";
                document.getElementById(id).style.display = "none";
              } else {
                document.getElementById(nid).style.display = "none";
              }
            },
          },
        },
      });
    });
  };
  return (
    <>
      <div className="row m-t-2e border-b-1">
        {props.AdvReportData.subject_wise_comparison.length > 0 && (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1">
            <h3>Subject wise Analysis of Previous and Present tests</h3>
          </div>
        )}
        {props.AdvReportData.subject_wise_comparison.length == 0 && (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex m-2e">
            <h5 className="m-auto">Previous Tests History is Not Available</h5>
          </div>
        )}
        {props.AdvReportData.subject_wise_comparison &&
          props.AdvReportData.subject_wise_comparison.map((item, index) => (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1 m-t-2e p-b-2e">
              <h5 className="text-center f-w-600">
                {index + 1} . {item.subject_name}{" "}
              </h5>
              <canvas id={`subWiseLine-${index}`} height="100"></canvas>
              <p
                className="text-center"
                id={`no-subWiseLine-data-${index}`}
                style={{ height: "inherit" }}
              >
                <span className="m-auto">
                  Previous Tests History is Not Available
                </span>
              </p>
            </div>
          ))}
      </div>
    </>
  );
};
export default AttemptAnalysis;
