import React, { useEffect } from "react";
import { Chart } from "chart.js";
const SubjectWiseGraph = (props) => {
  useEffect(() => {
    timeMgmntLine();
  }, []);
  const timeMgmntLine = () => {
    props.AdvReportData.subject_wise_comparison.forEach((sub, index) => {
      let linelabels = [];
      let linedata = [];
      var str = [];
      sub.tests_data.forEach((test, index) => {
        let test_name = "";
        if (test.test_name.length > 20) {
          test_name = test.test_name.substring(0, 20) + "...";
        } else {
          test_name = test.test_name;
        }

        linelabels.push(test_name);
        linedata.push((test.questions_answered / test.total_questions) * 100);
        str.push(test.questions_answered + " / " + test.total_questions);
      });
      var id = "timeMgmntLine-" + index;
      var nid = "no-timeMgmntLine-data-" + index;
      var canvas = document?.getElementById(id);
      var ctx = canvas?.getContext("2d");
      /* var ctx = document.getElementById("canvas-"+index).getContext("2d"); */
      var myLine = new Chart(ctx, {
        type: "line",
        data: {
          labels: linelabels,
          datasets: [
            {
              label: sub.subject_name,
              backgroundColor: "rgb(255, 99, 132)",
              borderColor: "rgb(255, 99, 132)",
              data: linedata,
              fill: false,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          responsive: true,
          tooltips: {
            mode: "label",
            callbacks: {
              title: function (t, d) {
                return d.labels[t[0].index];
              },
              beforeFooter: function (tooltipItems, data) {
                return str[tooltipItems[0].index];
              },
              footer: function (tooltipItems, data) {
                return "Questions Attempted";
              },
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Tests",
                },
                ticks: {
                  callback: function (value) {
                    var str = "utkarsh";
                    return str + "..."; //truncate
                  },
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Questions Attempted (in %)",
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          animation: {
            onComplete: function (animation) {
              var firstSet =
                animation.chart.config.data.datasets[0].data.length;

              if (firstSet < 2) {
                document.getElementById(nid).style.display = "flex";
                document.getElementById(id).style.display = "none";
              } else {
                document.getElementById(nid).style.display = "none";
              }
            },
          },
        },
      });
    });
  };
  return (
    <>
      <div className="row m-t-2e border-b-1">
        {props.AdvReportData.subject_wise_comparison.length > 0 ? (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1">
            <h3>
              Attempt Analysis of Previous and Present tests (Subject Wise)
            </h3>
          </div>
        ) : (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex m-2e">
            <h5 className="m-auto">Previous Tests History is Not Available</h5>
          </div>
        )}

        {props.AdvReportData.subject_wise_comparison &&
          props.AdvReportData.subject_wise_comparison.map((item, index) => (
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1 m-t-2e p-b-2e">
              <h5 className="text-center f-w-600">
                {" "}
                {index + 1} . {item.subject_name}{" "}
              </h5>
              <canvas id={`timeMgmntLine-${index}`} height="100"></canvas>
              <p
                className="text-center"
                id={`no-timeMgmntLine-data-${index}`}
                style={{ height: "inherit" }}
              >
                <span className="m-auto">
                  Previous Tests History is Not Available
                </span>
              </p>
            </div>
          ))}
      </div>
    </>
  );
};
export default SubjectWiseGraph;
