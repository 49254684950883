import Home from "../modules/home";
import Welcome from "../modules/welcome";
import Instructions from "../components/instructions";
import Panel from "../modules/panel";
import Confirm from "../components/confirm";
import NoQuestion from "../modules/noQuestion";
import Result from "../modules/results";
import AdvancedResult from "../modules/advancedresults";
import ScoreResult from "../modules/guestscore/";
import Registration from "../components/registration";

const routes = [
  { path: "/", exact: true, strict: true, component: Welcome },
  { path: "/auth/", strict: true, component: Home },
  { path: "/registration", strict: true, component: Registration },
  { path: "/instructions", exact: true, strict: true, component: Instructions },
  { path: "/panel", exact: true, strict: true, component: Panel },
  { path: "/confirm", exact: true, strict: true, component: Confirm },
  { path: "/noQuestion", exact: true, strict: true, component: NoQuestion },
  { path: "/results", exact: true, strict: true, component: Result },
  { path: "/advresult/", strict: true, component: AdvancedResult },
  { path: "/scoreresult", strict: true, component: ScoreResult },
];

export default routes;
