import React from "react";
import "../../css/welcome/welcome.css";
import { connect } from "react-redux";

const Welcome = (props) => {
  return (
    <>
      <div className="row text-center welcome-box">
        <div className="center-box m-auto pl-2 pr-2">
          <p className="lead mb-1 text-bold">Error 404! Page Not Found</p>
          <p className="text-small text-gray">
            The page you are looking for doesn't exist. Please use a valid link
            to access this site.
          </p>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(Welcome);
