import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import DonutChart from "react-donut-chart";
import currect from "../../../assets/icons/currect.png";
import wrong from "../../../assets/icons/wrongQues.png";
import "../advance.scss";
const QuestionWise = (props) => {
  // const canvasRef = React.createRef();
  const reactDonutChartdata = [
    {
      label: "Correct Answers",
      value: props.AdvReportData.result_summary.correct_questions,
      color: "#28a745",
    },
    {
      label: "Incorrect Answers",
      value: props.AdvReportData.result_summary.incorrect_questions,
      color: "#FF4560",
    },
    {
      label: "Unanswered",
      value: props.AdvReportData.result_summary.unattempted_questions,
      color: "#ffc107",
    },
  ];
  const reactDonutChartBackgroundColor = ["#28a745", "#FF4560", "#ffc107"];
  const reactDonutChartInnerRadius = 0.6;
  const reactDonutChartSelectedOffset = 0.08;
  const reactDonutChartHandleClick = (item, toggled) => {
    if (toggled) {
      console.log(item);
    }
  };
  let reactDonutChartStrokeColor = "#FFFFFF";
  const reactDonutChartOnMouseEnter = (item) => {
    let color = reactDonutChartdata.find((q) => q.label === item.label).color;
    reactDonutChartStrokeColor = color;
  };
  function decodeOption(opt) {
    return decodeURIComponent(escape(window.atob(opt)));
  }
  function getCorrectAnswerOptions(choices) {
    let check_correct_ans = [];
    choices.forEach((key, index) => {
      if (key.is_correct == 1) {
        check_correct_ans.push(getCharacter(index + 65));
      }
    });
    return check_correct_ans.toString();
  }
  function getOption(option_id) {
    option_id = parseInt(option_id);
    switch (option_id) {
      case 1:
        return "A";
        break;
      case 2:
        return "B";
        break;
      case 3:
        return "C";
        break;
      case 4:
        return "D";
        break;
      case 5:
        return "E";
        break;
      case 6:
        return "F";
        break;
      case 7:
        return "G";
        break;
      case 8:
        return "H";
        break;
      case 9:
        return "I";
        break;
      case 10:
        return "J";
        break;
      default:
        return "";
        break;
    }
    return String.fromCharCode(option_id);
  }
  function getAnswer(question) {
    if (question.ques_type != 6 && question.ques_type != 7) {
      if (question.student_answer.attempt_answer == "-1") {
        return "NA";
      } else {
        if (question.ques_type == 2) {
          return question.student_answer.attempt_answer;
        } else if (question.ques_type == 3) {
          let std_ans = question.student_answer.attempt_answer;
          let ans = std_ans.split(",");
          let check_arr = [];
          ans.forEach((val, index) => {
            if (val == "-1") {
              check_arr[index] = "NA";
            } else {
              check_arr[index] = getOption(val);
            }
          });
          return check_arr.toString();
        } else {
          return getOption(question.student_answer.attempt_answer);
        }
      }
    } else {
      let std_ans = question.student_answer.attempt_answer;
      let ans = std_ans.split(",");
      let check_arr = [];
      ans.forEach((val, index) => {
        let answer = val.split("=");
        if (answer[1] == "-1") {
          check_arr[index] = index + 1 + " = NA";
        } else {
          check_arr[index] = index + 1 + " = " + getOption(answer[1]);
        }
      });
      return check_arr.toString();
    }
  }

  function getCharacter(index) {
    return String.fromCharCode(index);
  }

  function convertMinutesToHours(time) {
    var time = time.toFixed(2);
    if (time <= 60) {
      return time + " mins";
    } else {
      return Math.floor(time / 60) + " hours " + (time % 60) + " mins";
    }
  }
  function getOption(option_id) {
    option_id = parseInt(option_id);
    switch (option_id) {
      case 1:
        return "A";
        break;
      case 2:
        return "B";
        break;
      case 3:
        return "C";
        break;
      case 4:
        return "D";
        break;
      case 5:
        return "E";
        break;
      case 6:
        return "F";
        break;
      case 7:
        return "G";
        break;
      case 8:
        return "H";
        break;
      case 9:
        return "I";
        break;
      case 10:
        return "J";
        break;
      default:
        return "";
        break;
    }
    return String.fromCharCode(option_id);
  }
  const data = {
    labels: ["I", "II", "III"],
    datasets: [
      {
        data: [
          props.AdvReportData.result_summary.correct_questions,
          props.AdvReportData.result_summary.incorrect_questions,
          props.AdvReportData.result_summary.unattempted_questions,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        borderWidth: 2,
      },
    ],
    options: {
      plugins: {
        datalabels: {
          display: true,
          align: "bottom",
          backgroundColor: "#ccc",
          borderRadius: 3,
          font: {
            size: 14,
          },
        },
      },
    },
  };

  useEffect(() => {
    // quePi()
  }, []);
  // const quePi = () => {
  //     var id = 'quePi';
  //     var canvas = canvasRef.current;
  //     var ctx = canvas.getContext("2d");
  //     var myBarChart = new Chart(ctx, {
  //         type: 'pie',
  //         data: {
  //             labels: ['Correct Answers', 'Incorrect Answers', 'Unanswered'],
  //             datasets: [{
  //                 backgroundColor: ["#2ecc71", "#e74c3c", "#f1c40f"],
  //                 data: [props.AdvReportData.result_summary.correct_questions, props.AdvReportData.result_summary.incorrect_questions, props.AdvReportData.result_summary.unattempted_questions]
  //             }]
  //         },

  //         // Configuration options go here
  //         options: {
  //             legend: {
  //                 display: true,
  //                 position: 'right',
  //             }
  //         }
  //     });
  // }

  return (
    <>
      <div class="row m-t-2e border-b-1">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1">
          <h3>Question wise analysis</h3>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {/* <Doughnut height={200} width={150} data={data} /> */}
          {/* <canvas ref={canvasRef} width={200} height={100}/> */}
          <DonutChart
            width={530}
            height={400}
            onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
            strokeColor={reactDonutChartStrokeColor}
            data={reactDonutChartdata}
            colors={reactDonutChartBackgroundColor}
            innerRadius={reactDonutChartInnerRadius}
            selectedOffset={reactDonutChartSelectedOffset}
            onClick={(item, toggled) =>
              reactDonutChartHandleClick(item, toggled)
            }
          />
        </div>
        {props.AdvReportData.subjects.map((item, index) => (
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h5>
              {" "}
              {index + 1} . {item.subject_name}{" "}
            </h5>
            {item.questions.map((question, qusInd) => (
              <div class="m-t-2e border-b-1">
                <p class="question">
                  <span class="f-w-600 pull-left">Q {question.order_no}. </span>
                  {question.alias_question !== null ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.alias_question,
                      }}
                    ></div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: question.question }}
                    ></div>
                  )}
                </p>
                {question.source_title && (
                  <p class="que-source">Source : {question.source_title}</p>
                )}
                {(question.ques_type == 1 || question.ques_type == 4) && (
                  <div class="row " style={{ width: "100%" }}>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <ul class="ans-ul">
                        {question.options.map((option, In) => (
                          <li style={{ display: "flex", marginTop: "5px" }}>
                            {question.student_answer !== null &&
                              option.is_correct === 1 &&
                              question.student_answer.attempt_answer ==
                                option.option_id && (
                                <img
                                  src={currect}
                                  height="20px"
                                  width="20px"
                                  style={{ marginLeft: "-21px" }}
                                />
                              )}
                            {question.student_answer !== null &&
                              option.is_correct === 0 &&
                              question.student_answer.attempt_answer ==
                                option.option_id && (
                                <img
                                  src={wrong}
                                  height="20px"
                                  width="20px"
                                  style={{ marginLeft: "-21px" }}
                                />
                              )}

                            <span class="ans-text">
                              {" "}
                              {getOption(option.option_id)}. &nbsp;
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: option.answer_option,
                                }}
                              ></div>
                            </span>
                          </li>
                        ))}
                        {question.ques_type != 2 && (
                          <li class="m-t-2e">
                            <span>
                              Correct Answer :
                              <strong>
                                {getOption(question.correct_option.option_id)}
                              </strong>
                            </span>
                          </li>
                        )}

                        <li>
                          <span>
                            Your Answer :
                            {question.student_answer !== null && (
                              <strong>{getAnswer(question)}</strong>
                            )}
                            {question.student_answer === null && (
                              <strong>NA</strong>
                            )}
                          </span>
                          {question.student_answer !== null && (
                            <span class="pull-right">
                              {question.student_answer.is_attempted == 0 && (
                                <span>
                                  <strong class="text-warning">
                                    Not Attempted
                                  </strong>
                                </span>
                              )}

                              {question.student_answer.is_correct == 1 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-success">
                                      Correct
                                    </strong>
                                  </span>
                                )}
                              {question.student_answer.is_correct == 0 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-danger">
                                      Incorrect
                                    </strong>
                                  </span>
                                )}
                            </span>
                          )}
                          {question.student_answer === null && (
                            <span class="pull-right">
                              <strong class="text-warning">
                                Not Attempted
                              </strong>
                            </span>
                          )}
                        </li>
                        <li>
                          <span>
                            Time Taken:{" "}
                            <strong>
                              {convertMinutesToHours(
                                question.student_answer.time_taken / 60000
                              )}{" "}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Assigned:{" "}
                            <strong>
                              {question.student_answer.maximum_marks}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Obtained:{" "}
                            <strong>
                              {question.student_answer.marks_obtained}
                            </strong>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {(question.ques_type == 6 || question.ques_type == 7) && (
                  <div class="row " style={{ width: "100%", display: "flex" }}>
                    <div style={{ height: "0px" }}>
                      {" "}
                      <ul class="ans-ul">
                        <span>
                          {question.student_answer !== null &&
                            question.student_answer.is_correct == 1 &&
                            question.student_answer.is_attempted == 1 && (
                              <img
                                src={currect}
                                height="20px"
                                width="20px"
                                style={{ margin: "0px" }}
                              />
                            )}
                          {question.student_answer !== null &&
                            question.student_answer.is_correct == 0 &&
                            question.student_answer.is_attempted == 1 && (
                              <img
                                src={wrong}
                                height="20px"
                                width="20px"
                                style={{ margin: "0px" }}
                              />
                            )}
                        </span>
                      </ul>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <ul class="ans-ul">
                        {question.options.map((option, In) => (
                          <li>
                            <span class="ans-text">
                              {option.option_id}. &nbsp;
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: option.answer_option,
                                }}
                              ></div>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                      <ul class="ans-ul">
                        {question.options.map((option, Ind2) => (
                          <li>
                            <span class="ans-text">
                              {getCharacter(Ind2 + 65)}. &nbsp;
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: option.answer_choice_option,
                                }}
                              ></div>
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div style={{ padding: "1em", width: "100%" }}>
                      <span>
                        Correct Answer :
                        {question.options.map((option, ind3) => (
                          <strong>
                            {option.option_id} -
                            {getCharacter(64 + (option.correct_ans - 0))} &nbsp;
                            &nbsp;
                          </strong>
                        ))}
                      </span>
                      <br />
                      <span>
                        Your Answer :
                        {question.student_answer !== null && (
                          <strong>{getAnswer(question)}</strong>
                        )}
                        {question.student_answer === null && (
                          <strong>NA</strong>
                        )}
                      </span>
                      {question.student_answer !== null && (
                        <span class="pull-right">
                          {question.student_answer.is_attempted == 0 && (
                            <span>
                              <strong class="text-warning">
                                Not Attempted
                              </strong>
                            </span>
                          )}
                          {question.student_answer.is_correct == 1 &&
                            question.student_answer.is_attempted == 1 && (
                              <span>
                                <strong class="text-success">Correct</strong>
                              </span>
                            )}
                          {question.student_answer.is_correct == 0 &&
                            question.student_answer.is_attempted == 1 && (
                              <span>
                                <strong class="text-danger">Incorrect</strong>
                              </span>
                            )}
                        </span>
                      )}
                      {question.student_answer === null && (
                        <span class="pull-right">
                          <strong class="text-warning">Not Attempted</strong>
                        </span>
                      )}
                    </div>

                    <div class="col-12 p-0">
                      <span>
                        Time Taken:{" "}
                        <strong>
                          {convertMinutesToHours(
                            question.student_answer.time_taken / 60000
                          )}{" "}
                        </strong>
                      </span>
                    </div>
                    <div class="col-12 p-0">
                      <span>
                        Marks Assigned:{" "}
                        <strong>
                          {question.student_answer.maximum_marks}{" "}
                        </strong>
                      </span>
                    </div>
                    <div class="col-12 p-0">
                      <span>
                        Marks Obtained:{" "}
                        <strong>
                          {question.student_answer.marks_obtained}
                        </strong>
                      </span>
                    </div>
                  </div>
                )}
                {question.ques_type == 3 && (
                  <div class="row " style={{ width: "100%" }}>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <ul class="ans-ul">
                        {question.options.map((option, Ind6) => (
                          <li
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            {question.student_answer !== null &&
                              option.is_correct === 1 &&
                              question.student_answer.attempt_answer ==
                                option.option_id && (
                                <img
                                  src={currect}
                                  height="20px"
                                  width="20px"
                                  style={{ margin: "0px" }}
                                />
                              )}
                            {question.student_answer !== null &&
                              option.is_correct === 0 &&
                              question.student_answer.attempt_answer ==
                                option.option_id && (
                                <img
                                  src={wrong}
                                  height="20px"
                                  width="20px"
                                  style={{ margin: "0px" }}
                                />
                              )}

                            <span class="ans-text">
                              {getOption(option.option_id)}. &nbsp;
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: option.answer_option,
                                }}
                              ></div>
                            </span>
                          </li>
                        ))}
                        <li class="m-t-2e">
                          <span>
                            Correct Answer :
                            <strong>
                              {getCorrectAnswerOptions(question.options)}
                            </strong>
                          </span>
                        </li>
                        <li></li>
                        <li>
                          <span>
                            Your Answer :
                            {question.student_answer !== null && (
                              <strong>{getAnswer(question)}</strong>
                            )}
                            {question.student_answer === null && (
                              <strong>NA</strong>
                            )}
                          </span>

                          {question.student_answer !== null && (
                            <span class="pull-right">
                              {question.student_answer.is_attempted == 0 && (
                                <span>
                                  <strong class="text-warning">
                                    Not Attempted
                                  </strong>
                                </span>
                              )}
                              {question.student_answer.is_correct == 1 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-success">
                                      Correct
                                    </strong>
                                  </span>
                                )}
                              {question.student_answer.is_correct == 0 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-danger">
                                      Incorrect
                                    </strong>
                                  </span>
                                )}
                            </span>
                          )}
                          {question.student_answer === null && (
                            <span class="pull-right">
                              <strong class="text-warning">
                                Not Attempted
                              </strong>
                            </span>
                          )}
                        </li>
                        <li>
                          <span>
                            Time Taken:{" "}
                            <strong>
                              {convertMinutesToHours(
                                question.student_answer.time_taken / 60000
                              )}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Assigned:{" "}
                            <strong>
                              {question.student_answer.maximum_marks}{" "}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Obtained:{" "}
                            <strong>
                              {question.student_answer.marks_obtained}
                            </strong>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {question.ques_type == 2 && (
                  <div class="row " style={{ width: "100%" }}>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <ul class="ans-ul">
                        {question.options.map((option, In5) => (
                          <>
                            <li
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "5px",
                              }}
                            >
                              {question.student_answer !== null &&
                                option.is_correct === 1 &&
                                question.student_answer.attempt_answer ==
                                  option.option_id && (
                                  <img
                                    src={currect}
                                    height="20px"
                                    width="20px"
                                  />
                                )}
                              {question.student_answer !== null &&
                                option.is_correct === 0 &&
                                question.student_answer.attempt_answer ==
                                  option.option_id && (
                                  <img
                                    src={wrong}
                                    height="20px"
                                    width="20px"
                                    style={{ margin: "0px" }}
                                  />
                                )}
                              <span class="ans-text">
                                {getOption(option.option_id)}. &nbsp;
                                <span></span>
                              </span>
                            </li>
                            {question.ques_type != 2 && (
                              <li class="m-t-2e">
                                <span>
                                  Correct Answer :
                                  <strong>
                                    {getOption(
                                      question.correct_option.option_id
                                    )}
                                  </strong>
                                </span>
                              </li>
                            )}
                          </>
                        ))}
                        <li>
                          <span>
                            Your Answer :
                            {question.student_answer !== null && (
                              <label
                                class="stud-answer"
                                dangerouslySetInnerHTML={{
                                  __html: getAnswer(question),
                                }}
                              />
                            )}
                            {question.student_answer === null && (
                              <strong>NA</strong>
                            )}
                          </span>
                          {question.student_answer !== null && (
                            <span class="pull-right">
                              {question.student_answer.is_attempted == 0 && (
                                <span>
                                  <strong class="text-warning">
                                    Not Attempted
                                  </strong>
                                </span>
                              )}
                              {question.student_answer.is_correct == 1 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-success">
                                      Correct
                                    </strong>
                                  </span>
                                )}
                              {question.student_answer.is_correct == 0 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-danger">
                                      Incorrect
                                    </strong>
                                  </span>
                                )}
                            </span>
                          )}
                          {question.student_answer === null && (
                            <span class="pull-right">
                              <strong class="text-warning">
                                Not Attempted
                              </strong>
                            </span>
                          )}
                        </li>
                        <li>
                          <span>
                            Time Taken:{" "}
                            <strong>
                              {convertMinutesToHours(
                                question.student_answer.time_taken / 60000
                              )}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Assigned:{" "}
                            <strong>
                              {question.student_answer.maximum_marks}{" "}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Obtained:{" "}
                            <strong>
                              {question.student_answer.marks_obtained}
                            </strong>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                {question.ques_type == 5 && (
                  <div class="row" style={{ width: "100%" }}>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <ul class="ans-ul">
                        <li style={{ display: "flex", marginTop: "5px" }}>
                          {question.student_answer !== null &&
                            question.student_answer.is_correct === 1 &&
                            question.student_answer.attempt_answer == "1" && (
                              <img
                                src={currect}
                                height="20px"
                                width="20px"
                                style={{ marginLeft: "-21px" }}
                              />
                            )}
                          {question.student_answer !== null &&
                            question.student_answer.is_correct === 0 &&
                            question.student_answer.attempt_answer == "1" && (
                              <img
                                src={wrong}
                                height="20px"
                                width="20px"
                                style={{ marginLeft: "-21px" }}
                              />
                            )}

                          <span class="ans-text">
                            {getOption(1)}. &nbsp;<span>TRUE</span>
                          </span>
                        </li>

                        <li style={{ display: "flex", marginTop: "5px" }}>
                          {question.student_answer !== null &&
                            question.student_answer.is_correct === 1 &&
                            question.student_answer.attempt_answer == "2" && (
                              <img
                                src={currect}
                                height="20px"
                                width="20px"
                                style={{ marginLeft: "-21px" }}
                              />
                            )}
                          {question.student_answer !== null &&
                            question.student_answer.is_correct === 0 &&
                            question.student_answer.attempt_answer == "2" && (
                              <img
                                src={wrong}
                                height="20px"
                                width="20px"
                                style={{ marginLeft: "-21px" }}
                              />
                            )}

                          <span class="ans-text">
                            {getOption(2)}. &nbsp;<span>FALSE</span>
                          </span>
                        </li>
                        <li class="m-t-2e">
                          <span>
                            Correct Answer :
                            {question.options[0].is_correct &&
                              question.options[0].option_id == 1 && (
                                <strong>{getOption(1)}</strong>
                              )}
                            {!question.options[0].is_correct &&
                              question.options[0].option_id == 1 && (
                                <strong>{getOption(2)}</strong>
                              )}
                          </span>
                        </li>
                        <li></li>
                        <li>
                          <span>
                            Your Answer :
                            {question.student_answer !== null && (
                              <strong>{getAnswer(question)}</strong>
                            )}
                            {question.student_answer === null && (
                              <strong>NA</strong>
                            )}
                          </span>

                          {question.student_answer !== null && (
                            <span class="pull-right">
                              {question.student_answer.is_attempted == 0 && (
                                <span>
                                  <strong class="text-warning">
                                    Not Attempted
                                  </strong>
                                </span>
                              )}
                              {question.student_answer.is_correct == 1 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-success">
                                      Correct
                                    </strong>
                                  </span>
                                )}
                              {question.student_answer.is_correct == 0 &&
                                question.student_answer.is_attempted == 1 && (
                                  <span>
                                    <strong class="text-danger">
                                      Incorrect
                                    </strong>
                                  </span>
                                )}
                            </span>
                          )}

                          {question.student_answer === null && (
                            <span class="pull-right">
                              <strong class="text-warning">
                                Not Attempted
                              </strong>
                            </span>
                          )}
                        </li>
                        <li>
                          <span>
                            Time Taken:{" "}
                            <strong>
                              {convertMinutesToHours(
                                question.student_answer.time_taken / 60000
                              )}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Assigned:{" "}
                            <strong>
                              {question.student_answer.maximum_marks}{" "}
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            Marks Obtained:{" "}
                            <strong>
                              {question.student_answer.marks_obtained}
                            </strong>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {(question.ques_feedback || question.alias_feedback) && (
                  <p>
                    <span class="f-w-600 pull-left">Explanation : </span>
                    <span>
                      {" "}
                      {question.alias_feedback != null ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.alias_feedback,
                          }}
                        ></div>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.ques_feedback,
                          }}
                        ></div>
                      )}
                    </span>
                  </p>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};
export default QuestionWise;
