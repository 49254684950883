import React, { useEffect, useState } from "react";
import api from "../../api";
import "./advance.scss";
import SubjectWiseAnalysis from "./subjectwiseanalysis";
import SubjectWiseAnalysisGraph from "./SubjectWiseGraph/subjectwisegraph";
import SectionWise from "./SectionWise/section_wise";
import TimeManagementAnalysis from "./TimeManagementAnalysis/timemanagementanalysis";
import AnalysisOfPrevious from "./Analysis_Of_Previous/analysis_of_previous_nd_presents";
import AttemptAnalysis from "./Attempt_Analysis/attempt_analysis";
import QuestionWise from "./QuestionWise/questionwise";
import Loader from "../../components/loader";

const AdvanceResults = () => {
  const [AdvReportData, setAdvReportData] = useState({});
  const [ResltAnalysis, setResultAnalysis] = useState(false);
  const [ResultAnalysisTab, setResultAnalysisTab] = useState(1);
  const [loading, setLoading] = useState(true);
  // 1 for  Subject Wise Analysis  2 for Time Management Analysis (Subject Wise)  3 for Question Wise
  const [ResultComparisonTab, setResultComparisonTab] = useState(0);
  // 1 for Analysis of Previous and Present tests 2 for Subject Wise Analysis of Tests 3 for Attempt Analysis of tests (Subject Wise)
  //   4 for Section wise (within the subject) Analysis of tests
  const AdvanceReportApi = () => {
    let request = {
      url: `/v1/examPanel/advanced-test-report/${window.location.href
        .split("/")
        .pop()}`,
    };
    api
      .getAuth(request)
      .then((response) => {
        setLoading(false);
        setAdvReportData(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const ChangeToggle = () => {
    setResultAnalysis(!ResltAnalysis);
    {
      if (ResltAnalysis == true) {
        setResultComparisonTab(0);
        setResultAnalysisTab(1);
      } else {
        setResultAnalysisTab(0);
        setResultComparisonTab(1);
      }
    }
  };

  const DateFormateAsERP = (data) => {
    const locDate = new Date(data);
    return locDate.toLocaleDateString("en-GB");
  };

  const Percentile = () => {
    let number =
      ((AdvReportData.result_summary.rank.total_students_appeared -
        AdvReportData.result_summary.rank.rank) /
        AdvReportData.result_summary.rank.total_students_appeared) *
      100;
    if (number > 0) {
      return number.toFixed(2);
    } else {
      return 0;
    }
  };
  const calcTimeTaken = (time) => {
    if (Math.floor(time / 60) > 0) {
      return Math.floor(time / 60) + " min " + (time % 60) + "sec";
    } else {
      return (time % 60) + "sec";
    }
  };

  useEffect(() => {
    AdvanceReportApi();
  }, []);
  return (
    <>
      {loading && (
        <div>
          <Loader />
        </div>
      )}
      {AdvReportData && Object.keys(AdvReportData).length > 0 && (
        <div
          className="row"
          style={{ overflow: "scroll", width: "100%", height: "100vh" }}
        >
          <div className="col-lg-1 col-md-1"></div>
          <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h3>Score Card</h3>
                <p>
                  <span>Test Name : </span>{" "}
                  <span style={{ fontSize: "15px", fontWeight: "600" }}>
                    {AdvReportData.test_info.test_title}
                  </span>
                </p>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <p>
                    Name :{" "}
                    <span className="f-w-600">
                      {AdvReportData.student_data.student_name}
                    </span>
                  </p>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12  text-left-xs pl-2"
                  style={{ paddingLeft: "25%" }}
                >
                  <p>
                    Student ID :{" "}
                    <span className="f-w-600">
                      {AdvReportData.student_data.student_display_id}
                    </span>
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <p>
                    No. of Questions :{" "}
                    <span className="f-w-600">
                      {AdvReportData.test_info.total_questions}
                    </span>
                  </p>
                  <p>
                    Total marks :{" "}
                    <span className="f-w-600">
                      {AdvReportData.test_info.assigned_marks}
                    </span>
                  </p>
                  <p>
                    Test Duration :
                    {AdvReportData.test_info.duration ? (
                      <span className="f-w-600">
                        {" "}
                        {AdvReportData.test_info.duration} Minutes
                      </span>
                    ) : (
                      <span className="f-w-600"> --</span>
                    )}
                  </p>
                  {/* <p>Total Negative marks : <span className="f-w-600"> {AdvReportData.test_info.negative_marks} marks</span></p> */}
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-left-xs pl-2"
                  style={{ paddingLeft: "25%" }}
                >
                  <p>
                    Date :{" "}
                    <span className="f-w-600">
                      {DateFormateAsERP(AdvReportData.test_info.test_date)}
                    </span>
                  </p>
                  <p>
                    Started on :
                    {AdvReportData.test_info.test_start_time ? (
                      <span className="f-w-600">
                        {" "}
                        {AdvReportData.test_info.test_start_time}
                      </span>
                    ) : (
                      <span className="f-w-600"> --</span>
                    )}
                  </p>
                  <p>
                    Submitted on :
                    {AdvReportData.test_info.test_end_time ? (
                      <span className="f-w-600">
                        {" "}
                        {AdvReportData.test_info.test_end_time}
                      </span>
                    ) : (
                      <span className="f-w-600"> --</span>
                    )}
                  </p>
                </div>
              </div>
              <hr />
              <div className="row text-center">
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>Percentile</p>
                  <div className="box">
                    {AdvReportData.result_summary.rank.total_students_appeared >
                    1 ? (
                      <p className="m-auto f-w-600">{Percentile()}</p>
                    ) : (
                      <p className="m-auto f-w-600">99</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>Score</p>
                  <div className="box">
                    <p className="m-auto">
                      <span className="f-w-600">
                        {
                          AdvReportData.result_summary.obtained_marks
                            .obtained_marks
                        }
                      </span>{" "}
                      /{" "}
                      {
                        AdvReportData.result_summary.obtained_marks
                          .assigned_marks
                      }
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>Rank</p>
                  <div className="box">
                    <p className="m-auto">
                      <span className="f-w-600">
                        {AdvReportData.result_summary.rank.rank}
                      </span>
                      <br />
                      <small>
                        {" "}
                        of
                        <span>
                          {" "}
                          {
                            AdvReportData.result_summary.rank
                              .total_students_appeared
                          }
                        </span>{" "}
                        Students
                      </small>
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>Time Taken </p>
                  <div className="box">
                    <p className="m-auto f-w-600">
                      {calcTimeTaken(AdvReportData.result_summary.time_taken)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row text-center" style={{ marginTop: "20px" }}>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>Attempted</p>
                  <div className="box">
                    <p className="m-auto f-w-600">
                      {AdvReportData.result_summary.attempted_questions}
                    </p>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>
                    Correct <span className="hidden-xs">Answers</span>
                  </p>
                  <div className="box">
                    <p className="m-auto f-w-600">
                      {AdvReportData.result_summary.correct_questions}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>
                    Incorrect <span className="hidden-xs">Answers</span>
                  </p>
                  <div className="box">
                    <p className="m-auto f-w-600">
                      {AdvReportData.result_summary.incorrect_questions}
                    </p>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                  <p>Total Negative Marks</p>
                  <div className="box">
                    <p className="m-auto f-w-600">
                      {AdvReportData.test_info.negative_marks}
                    </p>
                  </div>
                </div>
              </div>
              <br />
              <div className="row border-b-1 m-t-2e">
                {!ResltAnalysis ? (
                  <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8">
                    Result Analysis
                  </div>
                ) : (
                  <div className="col-lg-10 col-md-10 col-sm-8 col-xs-8">
                    Result Comparison With Previous Tests
                  </div>
                )}
                <div className="col-lg-2 col-md-2 col-sm-4 col-xs-4">
                  <div
                    className={`toggle-button ${
                      ResltAnalysis ? "toggle-button-selected" : ""
                    } pull-right`}
                    onClick={() => ChangeToggle()}
                  >
                    <span></span>
                  </div>
                </div>
              </div>
              {!ResltAnalysis ? (
                <div className="row">
                  <div
                    className={`col-lg-4 col-ms-4 col-sm-4 col-xs-12 list-card ${
                      ResultAnalysisTab == 1 && "active"
                    } chart-class`}
                    onClick={() => setResultAnalysisTab(1)}
                  >
                    <p className="m-auto">Subject Wise Analysis</p>
                  </div>
                  <div
                    className={`col-lg-4 col-ms-4 col-sm-4 col-xs-12 list-card ${
                      ResultAnalysisTab == 2 && "active"
                    } chart-class`}
                    onClick={() => setResultAnalysisTab(2)}
                  >
                    <p className="m-auto">
                      Time Management Analysis (Subject Wise)
                    </p>
                  </div>
                  <div
                    className={`col-lg-4 col-ms-4 col-sm-4 col-xs-12 list-card ${
                      ResultAnalysisTab == 3 && "active"
                    } chart-class`}
                    onClick={() => setResultAnalysisTab(3)}
                  >
                    <p className="m-auto">Question Wise</p>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div
                    className={`col-lg-3 col-ms-3 col-sm-3 col-xs-12 list-card  ${
                      ResultComparisonTab == 1 && "active"
                    } chart-class`}
                    onClick={() => {
                      setResultComparisonTab(1);
                    }}
                  >
                    <p className="m-auto">
                      Analysis of Previous and Present tests
                    </p>
                  </div>
                  <div
                    className={`col-lg-3 col-ms-3 col-sm-3 col-xs-12 list-card ${
                      ResultComparisonTab == 2 && "active"
                    } chart-class`}
                    onClick={() => {
                      setResultComparisonTab(2);
                    }}
                  >
                    <p className="m-auto">Subject Wise Analysis of Tests</p>
                  </div>
                  <div
                    className={`col-lg-3 col-ms-3 col-sm-3 col-xs-12 list-card ${
                      ResultComparisonTab == 3 && "active"
                    } chart-class`}
                    onClick={() => {
                      setResultComparisonTab(3);
                    }}
                  >
                    <p className="m-auto">
                      Attempt Analysis of tests (Subject Wise)
                    </p>
                  </div>
                  <div
                    className={`col-lg-3 col-ms-3 col-sm-3 col-xs-12 list-card ${
                      ResultComparisonTab == 4 && "active"
                    } chart-class`}
                    onClick={() => {
                      setResultComparisonTab(4);
                    }}
                  >
                    <p className="m-auto">
                      Section wise (within the subject) Analysis of tests
                    </p>
                  </div>
                </div>
              )}
              {ResultAnalysisTab == 1 && (
                <SubjectWiseAnalysis AdvReportData={AdvReportData} />
              )}
              {ResultAnalysisTab == 2 && (
                <TimeManagementAnalysis AdvReportData={AdvReportData} />
              )}
              {ResultAnalysisTab == 3 && (
                <div style={{ height: "20%" }}>
                  <QuestionWise AdvReportData={AdvReportData} />
                </div>
              )}

              {ResultComparisonTab == 1 && (
                <AnalysisOfPrevious AdvReportData={AdvReportData} />
              )}
              {ResultComparisonTab == 2 && (
                <SubjectWiseAnalysisGraph AdvReportData={AdvReportData} />
              )}
              {ResultComparisonTab == 3 && (
                <AttemptAnalysis AdvReportData={AdvReportData} />
              )}
              {ResultComparisonTab == 4 && (
                <SectionWise AdvReportData={AdvReportData} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AdvanceResults;
