import React from "react";
import "./confirm.css";
import notvisited from "../../assets/icons/not-visited.png";
import notanswered from "../../assets/icons/not-answered.png";
import answered from "../../assets/icons/answered.png";
import notanswerreview from "../../assets/icons/not-answered-review.png";
import answerreview from "../../assets/icons/answered-review.png";
import Icon from "../../assets/icons/Icon.svg";
import { submitTestApi } from "../../services/saveAndNext";
import { connect } from "react-redux";

const Confirm = (props) => {
  const submitTest = (isMannualSubmit = 0) => {
    props.setShow(false);
    submitTestApi(props, isMannualSubmit);
  };

  return (
    <>
      {
        <div className="popup-box">
          <div class="modal-body-md">
            <div class="headerv">
              <img src={Icon} />
              <h2>
                Are you sure you want to finish the test ?
                <p>
                  {" "}
                  If you click on SUBMIT, you will not be able to change your
                  answers.
                </p>
              </h2>
            </div>
            <div class="tt-8">
              <div>
                <img src={notvisited} /> Not Visited{" "}
                <span>
                  {
                    props.state.questionstatus.filter(
                      ({ status_number }) => status_number == 1
                    ).length
                  }
                </span>
              </div>
              <div>
                <img src={notanswered} /> Not Answered{" "}
                <span>
                  {
                    props.state.questionstatus.filter(
                      ({ status_number }) => status_number == 2
                    ).length
                  }
                </span>
              </div>
              <div>
                <img src={answered} /> Answered{" "}
                <span>
                  {
                    props.state.questionstatus.filter(
                      ({ status_number }) => status_number == 4
                    ).length
                  }
                </span>
              </div>
              <div>
                <img src={notanswerreview} /> Marked for Review{" "}
                <span>
                  {
                    props.state.questionstatus.filter(
                      ({ status_number }) => status_number == 3
                    ).length
                  }
                </span>
              </div>
              <div>
                <img src={answerreview} /> Answered & Marked for Review{" "}
                <span>
                  {
                    props.state.questionstatus.filter(
                      ({ status_number }) => status_number == 5
                    ).length
                  }
                </span>
              </div>
            </div>
            <div class="display">
              <button
                onClick={() => {
                  props.setShow(false);
                }}
              >
                CANCEL
              </button>
              {!(
                props.is_test_submit_status === 0 &&
                sessionStorage.getItem("leftTimeToSubmit") > 0
              ) ? (
                <button
                  onClick={() => {
                    submitTest(1);
                  }}
                >
                  SUBMIT
                </button>
              ) : (
                <div>
                  <button>SUBMIT</button>
                  <p
                    style={{ fontSize: "12px", color: "red", marginTop: "5px" }}
                  >
                    You cannot submit test before end time !
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(Confirm);
