import React, { useEffect, useState } from "react";
import api from "../../../api";
import SubmitStatus from "../../../components/submitloader";
import "./timer.css";
import { connect } from "react-redux";
import { submitTestApi } from "../../../services/saveAndNext";
import IitSectionTimer from "./iitsectiontimer";
const Timer = (props) => {
  const [leftTimeSubmit, setLeftTimeToSubmit] = useState(-2);
  const [testTimerstate, settestTimerstate] = useState(0);
  const [allSection, setAllSection] = useState([]);
  let leftTimeToExpire;
  let leftTimeToSubmit;
  let test_end_timestamp;
  const ServerTime = () => {
    let request = {
      url: `/v1/examPanel/time`,
      headers: {
        "X-Device-Info":
          "eyJkZXZpY2UiOiJVbmtub3duIiwiYnJvd3NlciI6IkNocm9tZSA5OS4wLjQ4NDQuODIiLCJvcyI6IldpbmRvd3Mgd2luZG93cy0xMCJ9",
        "X-Device-Key": "ozfinggcn7",
      },
    };
    api.getAuth(request).then((response) => {
      if (
        props.state?.attempt_data == undefined ||
        props.state?.attempt_data == null
      ) {
        leftTimeToExpire =
          parseInt(props.state.test_data.test_end_timestamp) -
          parseInt(response.data.timestamp);
        switch (props.state.test_data.test_type_id) {
          case 1: //Mock Test
            leftTimeToSubmit =
              props.state.test_data.duration < leftTimeToExpire
                ? parseInt(props.state.test_data.duration) * 60
                : leftTimeToExpire;
            test_end_timestamp = response.data.timestamp + leftTimeToSubmit;
            break;
          case 2: //Practice Test
            // this.leftTimeToSubmit = 0;
            leftTimeToSubmit =
              parseInt(props.state.test_data.duration) != 0
                ? parseInt(props.state.test_data.duration) * 60
                : 0;
            test_end_timestamp =
              parseInt(props.state.test_data.duration) != 0
                ? response.data.timestamp +
                  parseInt(props.state.test_data.duration) * 60
                : null;
            break;
          case 3: //Classroom Exam
          case 4: //Online Exam
            leftTimeToSubmit = leftTimeToExpire;
            test_end_timestamp = response.data.timestamp + leftTimeToSubmit;
            break;
        }
      } else if (props.state.test_data.test_type_id > 2) {
        //online and classroom
        leftTimeToSubmit =
          parseInt(props.state.attempt_data.test_end_timestamp) -
          parseInt(response.data.timestamp);
        setLeftTimeToSubmit(leftTimeToSubmit);
      } else if (props.state.test_data.test_type_id == 1) {
        //mock
        let attempt_start_ts = parseInt(
          props.state.attempt_data.attempt_start_timestamp
        );
        let test_duration = parseInt(props.state.test_data.duration) * 60;
        let attempt_end_timestamp = attempt_start_ts + test_duration;
        let test_end_timestamp = parseInt(
          props.state.attempt_data.test_end_timestamp
        );
        test_end_timestamp =
          attempt_end_timestamp < test_end_timestamp
            ? attempt_end_timestamp
            : test_end_timestamp;
        leftTimeToSubmit = test_end_timestamp - response.data.timestamp;
      } else {
        if (parseInt(props.state.test_data.duration) > 0) {
          let attempt_start_ts = parseInt(
            props.state.attempt_data.attempt_start_timestamp
          );
          let test_duration = parseInt(props.state.test_data.duration) * 60;

          test_end_timestamp = attempt_start_ts + test_duration;
          leftTimeToSubmit = test_end_timestamp - response.data.timestamp;
          setLeftTimeToSubmit(leftTimeToSubmit);
        } else {
          leftTimeToSubmit = -2;
          setLeftTimeToSubmit(leftTimeToSubmit);
          test_end_timestamp = null;
        }
      }

      if (
        leftTimeToSubmit <= 0 &&
        parseInt(props.state.test_data.duration) != 0 &&
        props.state.test_data.duration != ""
      ) {
        alert("This test is already expired");
        submitTestApi(props, 0);
      } else {
        if (parseInt(props.state.test_data.duration) > 0) {
          startTimer();
        }
      }
    });
  };
  const startTimer = () => {
    let testTimer = "";
    leftTimeToSubmit -= 2; //2 Seconds Network Latency
    const interval = setInterval(() => {
      leftTimeToSubmit -= 1;
      testTimer =
        Math.floor(leftTimeToSubmit / 3600) +
        " : " +
        ("0" + (Math.floor(leftTimeToSubmit / 60) % 60)).slice(-2) +
        " : " +
        ("0" + (leftTimeToSubmit % 60)).slice(-2);
      settestTimerstate(testTimer);
      sessionStorage.setItem("leftTimeToSubmit", leftTimeToSubmit);
      setLeftTimeToSubmit(leftTimeToSubmit);
      if (leftTimeToSubmit <= 0) {
        submitTestApi(props);
        clearInterval(interval);
      }
    }, 1000);
  };

  useEffect(() => {
    let allSectionloc = [];
    props.state.subjects_data.forEach((element) => {
      allSectionloc.push("" + element.subject_id + "#" + element.section_id);
    });
    setAllSection(allSectionloc);
    ServerTime();
  }, []);

  return (
    <>
      {leftTimeSubmit == 0 && <SubmitStatus />}
      {props?.state?.test_data?.is_neet_test == 1 &&
        props?.state?.test_data?.is_sec_time == 1 &&
        allSection.length > 0 && <IitSectionTimer allSection={allSection} />}
      {(testTimerstate != 0 && props?.state?.test_data?.is_sec_time == 0 &&
        // props?.state?.test_data?.is_neet_test == 1 &&
        // props?.state?.test_data?.is_sec_time == 0) ||
        // (props?.state?.test_data?.is_neet_test == 0 && 
        
          props?.state?.test_settings?.is_show_timer == 1 &&
          <div className="timerDiv">
            <span className="timeHeader">Time Left : </span>
            {testTimerstate && (
              <div style={{ display: "flex" }}>
                <div
                  className={
                    testTimerstate.split(":")[1] == 0
                      ? "timerunalert"
                      : "timerun"
                  }
                >
                  {testTimerstate.split(":")[0]}
                </div>{" "}
                <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                  :{" "}
                </span>
                <div
                  className={
                    testTimerstate.split(":")[1] == 0
                      ? "timerunalert"
                      : "timerun"
                  }
                >
                  {testTimerstate.split(":")[1]}
                </div>
                <span style={{ marginLeft: "5px", marginRight: "5px" }}>
                  :{" "}
                </span>
                <div
                  className={
                    testTimerstate.split(":")[1] == 0
                      ? "timerunalert"
                      : "timerun"
                  }
                >
                  {testTimerstate.split(":")[2]}
                </div>
              </div>
            )}
          </div>
        )}
    </>
  );
};

const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(Timer);
