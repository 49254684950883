import api from "../api";
import { Store } from "../store";
import { persistor } from "../store";

var payloadData = Store.getState();
const setQuestionNumberForApi = (questionstatus) => {
  let data = "";
  questionstatus.map((item, index) => {
    if (index === 0) {
      if (questionstatus.length == 1) {
        data = item.ques_id.toString();
      } else {
        data = item.ques_id.toString() + "#";
      }
    }
    if (index > 0 && index < questionstatus.length - 1) {
      data = data + item.ques_id + "#";
    }
    if (index != 0 && index === questionstatus.length - 1) {
      data = data + item.ques_id;
    }
  });
  return data;
};

const setAnswerJsonForApi = (answers_json, question_state) => {
  let originaldata = answers_json.map((item, index) => {
    return question_state[index]?.status_number == 4 ||
      question_state[index]?.status_number == 5
      ? item
      : "-1";
  });
  return originaldata.join("#");
};

const setQuestionStatusForApi = (questionstatus) => {
  let data = "";
  questionstatus.map((item, index) => {
    if (index === 0) {
      if (questionstatus.length == 1) {
        data = item.status_number.toString();
      } else {
        data = item.status_number.toString() + "#";
      }
    }
    if (index > 0 && index < questionstatus.length - 1) {
      data = data + item.status_number + "#";
    }
    if (index != 0 && index === questionstatus.length - 1) {
      data = data + item.status_number;
    }
  });
  return data;
};

function setSessionHeaders(payloadData) {
  let sessionKey = "";
  let accountdata = payloadData.institute_data;
  let testdata = payloadData.test_data;
  let studentdata = payloadData.student_data;
  if (
    studentdata != undefined &&
    testdata != undefined &&
    accountdata != undefined
  ) {
    sessionKey =
      studentdata.userid +
      ":" +
      testdata.test_id +
      ":" +
      accountdata.account_id +
      ":" +
      payloadData.session_key;
    sessionKey = btoa(sessionKey);

    return sessionKey;
  }
}

function completedSectionid(payloadData) {
  let sectionId = "";
  payloadData?.subjects_data.find((data) => {
    if (
      "" + data.subject_id + "#" + data.section_id !=
      payloadData.selectedSection
    ) {
      sectionId =
        sectionId + "" + data.subject_id + "#" + data.section_id + ",";
    }

    return (
      "" + data.subject_id + "#" + data.section_id ==
      payloadData.selectedSection
    );
  });
  return sectionId;
}

export default async function saveAndNext(props) {
  try {
    let payloadData = Store.getState();
    // Check user ID before proceeding
    if (payloadData.student_data.userid !== 1234567890) {
      let request = {
        url: `/v1/examPanel/saveTestState`,
        headers: {
          "X-Device-Key": localStorage.getItem("identityKey"),
          "X-Session-Key": setSessionHeaders(payloadData),
        },
        data: {
          data: {
            answers: Array.isArray(payloadData.answers_json)
              ? payloadData.answers_json.join("#")
              : payloadData.answers_json,
            attempt_time: payloadData.attempt_time.join("#"),
            exam_schd_id: payloadData.student_data.student_disp_id,
            files: {},
            filesV2: payloadData.question_fileV2_mapping,
            isComplete: 0,
            language_id: payloadData?.languageSelected,
            is_bookmark: payloadData.is_bookmarked.join("#"),
            question_state: setQuestionStatusForApi(payloadData.questionstatus),
            questions: setQuestionNumberForApi(payloadData.questionstatus),
            student_contact: payloadData.student_data.phone,
            student_disp_id: payloadData.student_data.student_disp_id,
            student_id: payloadData.student_data.student_id,
            student_name: payloadData.student_data.student_name,
            subjective_answer: payloadData.subjective_answer,
            testType: payloadData.test_data.test_type_id,
            test_id: payloadData.test_data.test_id,
            test_start_time: payloadData.startTime,
            test_end_time: payloadData.test_data.test_end_timestamp,
            totalTimeLeft: sessionStorage.getItem("leftTimeToSubmit"),
            user_id: payloadData.student_data.userid,
            completed_section_id: completedSectionid(payloadData),
            current_section_id: payloadData.selectedSection,
            current_section_time: parseInt(
              sessionStorage.getItem("SessionleftTimeToSubmit")
            ),
          },
        },
      };

      await api.postAuth(request);
      console.log("API call succeeded.");
    }
  } catch (error) {
    console.error("API call failed:", error);

    /* eslint-disable-next-line no-restricted-globals */
    alert(error?.response?.data?.errors?.message || "An unknown error occurred.");
    window.location.href = '/'
        window.close(); 
  }
}

export async function saveAndNextInsection(sectionTime) {
  try {
    let payloadData = Store.getState();

    if (payloadData.student_data.userid !== 1234567890) {
      let request = {
        url: `/v1/examPanel/saveTestState`,
        headers: {
          "X-Device-Key": localStorage.getItem("identityKey"),
          "X-Session-Key": setSessionHeaders(payloadData),
        },
        data: {
          data: {
            answers: Array.isArray(payloadData.answers_json)
              ? payloadData.answers_json.join("#")
              : payloadData.answers_json,
            attempt_time: payloadData.attempt_time.join("#"),
            exam_schd_id: payloadData.student_data.student_disp_id,
            files: {},
            filesV2: payloadData.question_fileV2_mapping,
            isComplete: 0,
            language_id: payloadData?.languageSelected,
            is_bookmark: payloadData.is_bookmarked.join("#"),
            question_state: setQuestionStatusForApi(payloadData.questionstatus),
            questions: setQuestionNumberForApi(payloadData.questionstatus),
            student_contact: payloadData.student_data.phone,
            student_disp_id: payloadData.student_data.student_disp_id,
            student_id: payloadData.student_data.student_id,
            student_name: payloadData.student_data.student_name,
            subjective_answer: payloadData.subjective_answer,
            testType: payloadData.test_data.test_type_id,
            test_id: payloadData.test_data.test_id,
            test_start_time: payloadData.startTime,
            test_end_time: payloadData.test_data.test_end_timestamp,
            totalTimeLeft: sessionStorage.getItem("leftTimeToSubmit"),
            user_id: payloadData.student_data.userid,
            completed_section_id: completedSectionid(payloadData),
            current_section_id: payloadData.selectedSection,
            current_section_time: sectionTime,
          },
        },
      };

      await api.postAuth(request);
    }
  } catch (error) {
    console.error("An error occurred:", error);

    // eslint-disable-next-line no-restricted-globals
    alert(error?.response?.data?.errors?.message || "An unknown error occurred.");
    window.location.href = '/'
      window.close(); 
  }
}


export async function submitTestApi(props,isMannualSubmit=0) {
  debugger
  props.dispatch({ type: "TEST_SUBMIT", testSubmitted: false });
  let payloadData = Store.getState();
  if (payloadData.student_data.userid != 1234567890) {
    let request = {
      url: `/v1/examPanel/submitTest`,
      headers: {
        "X-Device-Key": localStorage.getItem("identityKey"),
        "X-Session-Key": setSessionHeaders(payloadData),
      },
      data: {
        data: {
          answers: setAnswerJsonForApi(
            payloadData.answers_json,
            payloadData.questionstatus
          ),
          attempt_time: payloadData.attempt_time.join("#"),
          exam_schd_id: payloadData.student_data.student_disp_id,
          files: {},
          filesV2: payloadData.question_fileV2_mapping,
          isComplete: 1,
          language_id: payloadData?.languageSelected,
          is_bookmark: payloadData.is_bookmarked.join("#"),
          question_state: setQuestionStatusForApi(payloadData.questionstatus),
          questions: setQuestionNumberForApi(payloadData.questionstatus),
          student_contact: payloadData.student_data.phone,
          student_disp_id: payloadData.student_data.student_disp_id,
          student_id: payloadData.student_data.student_id,
          student_name: payloadData.student_data.student_name,
          subjective_answer: payloadData.subjective_answer,
          testType: payloadData.test_data.test_type_id,
          test_id: payloadData.test_data.test_id,
          test_start_time: payloadData.startTime,
          test_end_time: payloadData.test_data.test_end_timestamp,
          totalTimeLeft:
            payloadData.test_data.test_end_timestamp - payloadData.startTime,
          user_id: payloadData.student_data.userid,
          is_student_submit :isMannualSubmit
        },
        institute_id: payloadData?.institute_data.institute_id,
      },
    };
    await api.postAuth(request).then((response) => {
      if (
        payloadData.showscorecard &&
        parseInt(payloadData.test_settings.is_show_score) > 0
      ) {
        window.location.assign("/results");
        props.dispatch({ type: "TEST_SUBMIT", testSubmitted: true });
      } else {
        props.dispatch({ type: "TEST_SUBMIT", testSubmitted: true });
      }
    });
  } else {
    props.dispatch({ type: "TEST_SUBMIT", testSubmitted: true });
  }
}
