import React from "react";
import notvisited from "../../../assets/icons/not-visited.png";
import notanswered from "../../../assets/icons/not-answered.png";
import answered from "../../../assets/icons/answered.png";
import notanswerreview from "../../../assets/icons/not-answered-review.png";
import answerreview from "../../../assets/icons/answered-review.png";

const KanndaInstraction = (props) => {
  return (
    <>
      <div className="col-md-12 test-instructions">
        <p className="msgTitle">ಟೆಸ್ಟ್ ಸೂಚನೆಗಳು</p>
        <div>{props.instruction}</div>
      </div>

      <div className="col-md-12 general-instructions">
        <p className="msgTitle">ಸಾಮಾನ್ಯ ಸೂಚನೆಗಳು:</p>
        <section>
          <ol>
            <li className="msgFont">
              ಗಡಿಯಾರವು ಸರ್ವರ್ನಲ್ಲಿ ಹೊಂದಿಸಲ್ಪಡುತ್ತದೆ. ಪರದೆಯ ಮೇಲಿನ ಬಲ ಮೂಲೆಯಲ್ಲಿರುವ
              ಕೌಂಟ್ಡೌನ್ ಟೈಮರ್ ನೀವು ಪರೀಕ್ಷೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಲು ಉಳಿದಿರುವ ಸಮಯವನ್ನು
              ಪ್ರದರ್ಶಿಸುತ್ತದೆ. ಟೈಮರ್ ಶೂನ್ಯವನ್ನು ತಲುಪಿದಾಗ, ಪರೀಕ್ಷೆಯು ಸ್ವತಃ
              ಕೊನೆಗೊಳ್ಳುತ್ತದೆ. ನಿಮ್ಮ ಪರೀಕ್ಷೆಯನ್ನು ಅಂತ್ಯಗೊಳಿಸಲು ಅಥವಾ ಸಲ್ಲಿಸಲು
              ನಿಮಗೆ ಅಗತ್ಯವಿರುವುದಿಲ್ಲ
            </li>
            <li className="msgFont">
              ಪರದೆಯ ಬಲಭಾಗದಲ್ಲಿ ತೋರಿಸಲಾಗುವ ಪ್ರಶ್ನೆಗಳು ಪ್ಯಾಲೆಟ್ ಕೆಳಗಿನ
              ಪ್ರಶ್ನೆಗಳಲ್ಲಿ ಒಂದನ್ನು ಬಳಸಿ ಪ್ರತಿ ಪ್ರಶ್ನೆಯ ಸ್ಥಿತಿಯನ್ನು ತೋರಿಸುತ್ತದೆ:
              <ul className="legends-listing" type="none">
                <li className="msgFont">
                  <img src={notvisited} />{" "}
                  <span>ನೀವು ಇನ್ನೂ ಪ್ರಶ್ನೆಯನ್ನು ಭೇಟಿ ಮಾಡಿಲ್ಲ.</span>
                </li>
                <li className="msgFont">
                  <img src={notanswered} />
                  <span>ನೀವು ಪ್ರಶ್ನೆಗೆ ಉತ್ತರಿಸಲಿಲ್ಲ.</span>
                </li>
                <li className="msgFont">
                  <img src={answered} />
                  ನೀವು ಪ್ರಶ್ನೆಗೆ ಉತ್ತರ ನೀಡಿದ್ದೀರಿ.
                </li>
                <li className="msgFont">
                  <img src={notanswerreview} /> ನೀವು ಪ್ರಶ್ನೆಗೆ ಉತ್ತರಿಸಲಿಲ್ಲ,
                  ಆದರೆ ವಿಮರ್ಶೆಗಾಗಿ ಪ್ರಶ್ನೆಯನ್ನು ಗುರುತಿಸಿದ್ದೀರಿ.
                </li>
                <li className="msgFont">
                  <img src={answerreview} /> ಪ್ರಶ್ನೆ (ಗಳು) "ಉತ್ತರ ಮತ್ತು
                  ವಿಮರ್ಶೆಗಾಗಿ ಗುರುತಿಸಲಾಗಿದೆ" evalution ಪರಿಗಣಿಸಲಾಗುತ್ತದೆ.
                </li>
              </ul>
            </li>
            <li className="msgFont">
              ಪ್ರಶ್ನೆ ಪ್ಯಾಲೆಟ್ ಅನ್ನು ಕುಸಿಯಲು ಪ್ರಶ್ನೋತ್ತರ ಪ್ಯಾಲೆಟ್ನ ಎಡಕ್ಕೆ ಮನವಿ
              ಮಾಡುವ ಬಾಣದ ಮೇಲೆ ನೀವು ಕ್ಲಿಕ್ಕಿಸಬಹುದು. ಮತ್ತೆ ಪ್ರಶ್ನೆ ಪ್ಯಾಲೆಟ್
              ವೀಕ್ಷಿಸಲು, ನೀವು ಪ್ರಶ್ನೆ ವಿಂಡೋದ ಬಲಭಾಗದಲ್ಲಿ ಕಾಣಿಸಿಕೊಳ್ಳುವ ಕ್ಲಿಕ್
              ಮಾಡಬಹುದು.
            </li>
            <li className="msgFont">
              ಸಂಪೂರ್ಣ ಪ್ರಶ್ನೆ ಕಾಗದದ ಪರೀಕ್ಷೆಯ ಸಮಯದಲ್ಲಿ ಭಾಷೆಯನ್ನು ಬದಲಾಯಿಸಲು ನಿಮ್ಮ
              ಪರದೆಯ ಮೇಲಿನ ಬಲ ಮೂಲೆಯಲ್ಲಿರುವ ನಿಮ್ಮ "ಪ್ರೊಫೈಲ್" ಚಿತ್ರದ ಮೇಲೆ ನೀವು
              ಕ್ಲಿಕ್ ಮಾಡಬಹುದು. ಪ್ರೊಫೈಲ್ ಚಿತ್ರದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ನೀವು
              ಪ್ರಶ್ನೆ ವಿಷಯವನ್ನು ಬಯಸಿದ ಭಾಷೆಗೆ ಬದಲಾಯಿಸಲು ಡ್ರಾಪ್-ಡೌನ್ ಪಡೆಯುತ್ತೀರಿ.
            </li>
            <li className="msgFont">
              ನೀವು ಕೆಳಕ್ಕೆ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಸ್ಕ್ರಾಲಿಂಗ್
              ಮಾಡದೆಯೇ ಪ್ರಶ್ನೆಯ ಮೇಲ್ಭಾಗಕ್ಕೆ ನ್ಯಾವಿಗೇಟ್ ಮಾಡಲು ಸಾಧ್ಯವಿರುತ್ತದೆ.
            </li>
          </ol>
        </section>

        <section>
          <p className="msgFont">
            <b>ಒಂದು ಪ್ರಶ್ನೆಗೆ ನ್ಯಾವಿಗೇಟ್ ಮಾಡುವುದು:</b>
          </p>
          <ol start="6">
            <li className="msgFont">
              ಒಂದು ಪ್ರಶ್ನೆಗೆ ಉತ್ತರಿಸಲು, ಕೆಳಗಿನವುಗಳನ್ನು ಮಾಡಿ:
              <ol type="a">
                <li className="msgFont">
                  ಆ ಸಂಖ್ಯೆಯ ಪ್ರಶ್ನೆಗೆ ನೇರವಾಗಿ ಹೋಗಲು ನಿಮ್ಮ ಪರದೆಯ ಬಲದಲ್ಲಿರುವ
                  ಪ್ರಶ್ನೆ ಪ್ಯಾಲೆಟ್ನಲ್ಲಿರುವ ಪ್ರಶ್ನೆಯ ಸಂಖ್ಯೆಯನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ. ಈ
                  ಆಯ್ಕೆಯನ್ನು ಬಳಸಿಕೊಂಡು ಪ್ರಸ್ತುತ ಪ್ರಶ್ನೆಗೆ ನಿಮ್ಮ ಉತ್ತರವನ್ನು
                  ಉಳಿಸುವುದಿಲ್ಲ ಎಂದು ಗಮನಿಸಿ.
                </li>
                <li className="msgFont">
                  ಪ್ರಸ್ತುತ ಪ್ರಶ್ನೆಗೆ ನಿಮ್ಮ ಉತ್ತರವನ್ನು ಉಳಿಸಲು ಮತ್ತು ಮುಂದೆ ಕ್ಲಿಕ್
                  ಮಾಡಿ ಮತ್ತು ನಂತರ ಮುಂದಿನ ಪ್ರಶ್ನೆಗೆ ಹೋಗಿ.
                </li>
                <li className="msgFont">
                  ವಿಮರ್ಶೆಗಾಗಿ ಮಾರ್ಕ್ ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಪ್ರಸ್ತುತ ಪ್ರಶ್ನೆಗಾಗಿ
                  ನಿಮ್ಮ ಉತ್ತರವನ್ನು ಉಳಿಸಲು ಮುಂದೆ , ಅದನ್ನು ವಿಮರ್ಶೆಗಾಗಿ ಗುರುತಿಸಿ,
                  ಮತ್ತು ಮುಂದಿನ ಪ್ರಶ್ನೆಗೆ ಹೋಗಿ.
                </li>
              </ol>
            </li>
          </ol>
        </section>

        <section>
          <p className="msgFont">
            <b>ಒಂದು ಪ್ರಶ್ನೆಗೆ ಉತ್ತರಿಸುವಿಕೆ:</b>
          </p>
          <ol start="7">
            <li className="msgFont">
              ಬಹು ಆಯ್ಕೆಯ ಪ್ರಕಾರ ಪ್ರಶ್ನೆಗೆ ಉತ್ತರಿಸುವ ವಿಧಾನ:
              <ol type="a">
                <li className="msgFont">
                  ನಿಮಗೆ ಉತ್ತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು, ಆಯ್ಕೆಗಳ ಒಂದು ಗುಂಡಿಯನ್ನು ಕ್ಲಿಕ್
                  ಮಾಡಿ.
                </li>
                <li className="msgFont">
                  ನಿಮ್ಮ ಆಯ್ಕೆ ಉತ್ತರವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು, ಆಯ್ಕೆಮಾಡಿದ ಆಯ್ಕೆಯನ್ನು
                  ಮತ್ತೆ ಕ್ಲಿಕ್ ಮಾಡಿ ಅಥವಾ ತೆರವುಗೊಳಿಸಿ ರೆಸ್ಪಾನ್ಸ್ ಬಟನ್ ಅನ್ನು
                  ಕ್ಲಿಕ್ ಮಾಡಿ
                </li>
                <li className="msgFont">
                  ನಿಮ್ಮ ಆಯ್ಕೆ ಉತ್ತರವನ್ನು ಬದಲಾಯಿಸಲು, ಮತ್ತೊಂದು ಆಯ್ಕೆಯ ಗುಂಡಿಯನ್ನು
                  ಕ್ಲಿಕ್ ಮಾಡಿ
                </li>
                <li className="msgFont">
                  ನಿಮ್ಮ ಉತ್ತರವನ್ನು ಉಳಿಸಲು, ಉಳಿಸು ಮತ್ತು ಮುಂದಿನ ಗುಂಡಿಯನ್ನು ಕ್ಲಿಕ್
                  ಮಾಡಿ.
                </li>
                <li className="msgFont">
                  ವಿಮರ್ಶೆಗಾಗಿ ಪ್ರಶ್ನೆಯನ್ನು ಗುರುತಿಸಲು, ವಿಮರ್ಶೆ ಮತ್ತು ಮುಂದಿನ
                  ಗುಂಡಿಗಾಗಿ ಮಾರ್ಕ್ ಅನ್ನು ಕ್ಲಿಕ್ ಮಾಡಿ.
                </li>
              </ol>
            </li>
            <li className="msgFont">
              ಈಗಾಗಲೇ ಉತ್ತರಿಸಲ್ಪಟ್ಟ ಪ್ರಶ್ನೆಯೊಂದಕ್ಕೆ ನಿಮ್ಮ ಉತ್ತರವನ್ನು ಬದಲಿಸಲು,
              ಮೊದಲು ಉತ್ತರವನ್ನು ಆ ಪ್ರಶ್ನೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ ಮತ್ತು ಆ ರೀತಿಯ ಪ್ರಶ್ನೆಗೆ
              ಉತ್ತರಿಸುವ ವಿಧಾನವನ್ನು ಅನುಸರಿಸಿ.
            </li>
          </ol>
        </section>

        <section>
          <p className="msgFont">
            <b>ವಿಭಾಗಗಳ ಮೂಲಕ ನ್ಯಾವಿಗೇಟ್ ಮಾಡುವುದು:</b>
          </p>
          <ol start="9">
            <li className="msgFont">
              ಈ ಪ್ರಶ್ನೆಯ ಕಾಗದದ ವಿಭಾಗಗಳನ್ನು ಪರದೆಯ ಮೇಲಿನ ಪಟ್ಟಿಯಲ್ಲಿ
              ಪ್ರದರ್ಶಿಸಲಾಗುತ್ತದೆ. ಒಂದು ವಿಭಾಗದಲ್ಲಿನ ಪ್ರಶ್ನೆಗಳು ವಿಭಾಗದ ಹೆಸರಿನ ಮೇಲೆ
              ಕ್ಲಿಕ್ ಮಾಡುವ ಮೂಲಕ ವೀಕ್ಷಿಸಬಹುದು. ನೀವು ಪ್ರಸ್ತುತ ವೀಕ್ಷಿಸುತ್ತಿರುವ
              ವಿಭಾಗವನ್ನು ಹೈಲೈಟ್ ಮಾಡಲಾಗಿದೆ.
            </li>
            <li className="msgFont">
              ಒಂದು ವಿಭಾಗಕ್ಕಾಗಿ ಕೊನೆಯ ಪ್ರಶ್ನೆಯಲ್ಲಿ ಉಳಿಸು ಮತ್ತು ಮುಂದಿನ ಗುಂಡಿಯನ್ನು
              ಕ್ಲಿಕ್ ಮಾಡಿದ ನಂತರ, ನೀವು ಮುಂದಿನ ವಿಭಾಗದ ಮೊದಲ ಪ್ರಶ್ನೆಗೆ
              ಸ್ವಯಂಚಾಲಿತವಾಗಿ ತೆಗೆದುಕೊಳ್ಳಲಾಗುವುದು.
            </li>
            <li className="msgFont">
              ಸಮಯದ ವೇಳೆಯಲ್ಲಿ ನಿಮ್ಮ ಅನುಕೂಲಕ್ಕಾಗಿ ನೀವು ಪರೀಕ್ಷೆಯ ಸಮಯದಲ್ಲಿ ವಿಭಾಗಗಳು
              ಮತ್ತು ಪ್ರಶ್ನೆಗಳ ನಡುವೆ ಯಾವುದಾದರೂ ಷಫಲ್ ಮಾಡಬಹುದು.
            </li>
            <li className="msgFont">
              ಅಭ್ಯರ್ಥಿಯು ಪ್ರಶ್ನೆ ಪ್ಯಾಲೆಟ್ ಮೇಲೆ ಪ್ರತಿ ವಿಭಾಗದಲ್ಲಿ ಕಾಣಿಸಿಕೊಳ್ಳುವ
              ದಂತಕಥೆಯ ಭಾಗವಾಗಿ ಅನುಗುಣವಾದ ವಿಭಾಗವನ್ನು ಬೇಸಿಗೆಯಲ್ಲಿ ವೀಕ್ಷಿಸಬಹುದು.
            </li>
          </ol>
        </section>
      </div>
    </>
  );
};
export default KanndaInstraction;
