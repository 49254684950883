import React from "react";
import AvtarImg from "../../../assets/images/default_avtar.png";
import el_user from "../../../assets/icons/el_user.svg";
import SubmitStatus from "../../../components/submitloader";
import "./student_details.css";
import { connect } from "react-redux";

const StudentDetails = (props) => {
  return (
    <>
      <div className="studentInformation">
        <img
          height="50px"
          width="50px"
          style={{ margin: 0 }}
          src={
            props.state.student_data.photo !== null
              ? props.state.student_data.photo
              : el_user
          }
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
          }}
        >
          <span className="studentName">{props.state.student_data.name} </span>
          <span className="contactNum">{props.state.student_data.phone}</span>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(StudentDetails);
