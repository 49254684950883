import "./App.css";
import { connect } from "react-redux";
import Routes from "./routes";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useEffect, useState } from "react";
import Latex from "./components/Latex";
import feviconIcon from "./assets/icons/logo.png";

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

function App(props) {
  const favicon = document.getElementById("favicon");
  // favicon.href = feviconIcon;
  favicon.href = `${feviconIcon}`;
  const handle = useFullScreenHandle();
  const [actions, setActions] = useState([]);
  const handleVisibilityChange = () => {
    if (document[hidden]) {
      setActions([...actions, "true"]);
    }
  };
  useEffect(() => {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    if (handle.active == false) {
      handle.enter();
    }
  }, [props]);
  useEffect(() => {
    props.dispatch({ type: "ILLEGAL_COUNT", illegalCount: 1 });
  }, [actions]);

  return (
    <FullScreen handle={handle}>
      <div>
        <Latex>
          <Routes />
        </Latex>
      </div>
    </FullScreen>
  );
}

const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(App);
