const initialState = {
  test_language: {},
  institute_data: {},
  current_section_id: "",
  student_data: {},
  test_data: {},
  is_bookmarked: [],
  attempt_data: {},
  registrationdata: {},
  questionstatus: [],
  testSubmitted: "",
  attempt_time: [],
  subjective_answer: [],
  ques_data: [],
  subjects_data: [],
  startTime: 0,
  illegalCount: 0,
  authorised: "",
  session_key: "",
  test_settings: {},
  showscorecard: false,
  activeQuesInd: 0,
  leftTimeToSubmit: -2,
  languageSelected: 1,
  answers_json: [],
  question_fileV2_mapping: {},
  selectedSection: "",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "LOGININTIAL": {
      return {
        test_language: {},
        institute_data: {},
        current_section_id: "",
        student_data: {},
        test_data: {},
        is_bookmarked: [],
        attempt_data: {},
        registrationdata: {},
        questionstatus: [],
        testSubmitted: "",
        attempt_time: [],
        subjective_answer: [],
        ques_data: [],
        subjects_data: [],
        startTime: 0,
        illegalCount: 0,
        authorised: "",
        session_key: "",
        test_settings: {},
        showscorecard: false,
        activeQuesInd: 0,
        leftTimeToSubmit: -2,
        languageSelected: 1,
        answers_json: [],
        question_fileV2_mapping: {},
      };
    }

    case "AUTHORIZATION": {
      return {
        ...state,
        test_language: action.test_language,
        institute_data: action.institute_data,
        student_data: action.student_data,
        test_data: action.test_data,
        authorised: action.authorised,
        session_key: action.session_key,
        attempt_data: action.attempt_data,
        activeQuesInd: 0,
      };
    }
    case "REGISTRATION": {
      return {
        ...state,
        registrationdata: action.registrationdata,
      };
    }
    case "CHANGES_SESSION": {
      return {
        ...state,
        session_key: action.session_key,
      };
    }
    case "SHOW_SCORECARD": {
      return {
        ...state,
        showscorecard: action.showscorecard,
      };
    }
    case "SET_ANSWER": {
      return {
        ...state,
        answers_json: action.answers_json,
      };
    }
    case "SET_BOOKMARKED": {
      return {
        ...state,
        is_bookmarked: action.is_bookmarked,
      };
    }
    case "CHANGES_STATUS": {
      return {
        ...state,
        questionstatus: action.questionstatus,
      };
    }
    case "CURRENT_SECTION": {
      return {
        ...state,
        current_section_id: action.current_section_id,
      };
    }
    case "ATTEMPT_DATA": {
      return {
        ...state,
        attempt_data: action.attempt_data,
      };
    }
    case "QUESTIONS_DATA": {
      return {
        ...state,
        ques_data: action.ques_data,
      };
    }
    case "CHANGE_LANGUAGE_QUESTION": {
      return {
        ...state,
        test_language: action.test_language,
      };
    }
    case "CHANGES_LANGUAGE": {
      return {
        ...state,
        languageSelected: action.languageSelected,
      };
    }

    case "ADD_QUESTION_FILESV2": {
      return {
        ...state,
        question_fileV2_mapping: action.question_fileV2_mapping,
      };
    }

    case "ATTEMPT_TIME": {
      return {
        ...state,
        attempt_time: action.attempt_time,
      };
    }
    case "START_TIME": {
      return {
        ...state,
        startTime: action.startTime,
      };
    }
    case "ILLEGAL_COUNT": {
      return {
        ...state,
        illegalCount: action.illegalCount === 1 ? state.illegalCount + 1 : 0,
      };
    }
    case "TEST_SETTING": {
      return {
        ...state,
        test_settings: action.test_settings,
      };
    }
    case "TEST_SUBMIT": {
      return {
        ...state,
        testSubmitted: action.testSubmitted,
      };
    }
    case "SUBJECT_DATA": {
      return {
        ...state,
        subjects_data: action.subjects_data,
      };
    }
    case "SUBJECTIVE_ANSWERS": {
      return {
        ...state,
        subjective_answer: action.subjective_answer,
      };
    }
    case "ACTIVE_QUESTION_NUMBER": {
      return {
        ...state,
        activeQuesInd: action.activeQuesInd,
      };
    }
    case "LEFT_TIME_TOSUBMIT": {
      return {
        ...state,
        leftTimeToSubmit: action.leftTimeToSubmit,
      };
    }
    case "SELECTED_SUBJECT": {
      return {
        ...state,
        selectedSection: action.selectedSection,
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default reducer;
