import React, { useState } from "react";
import "./file_upload.css";
import { connect } from "react-redux";
import api from "../../api";

const FileUpload = (props) => {
  const [finalSelectedFile, setfinalSelectedFile] = useState({});
  let question_fileV2_mapping = props.state.question_fileV2_mapping
    ? props.state.question_fileV2_mapping
    : {};

  const [fileArray, setfileArray] = useState([]);

  const closeUploadBox = () => {
    props.setshowUploadBox(false);
    setfinalSelectedFile({});
  };

  const fillFile = () => {
    setTimeout(() => {
      var uploadedFile = document.getElementById("uploadFileControl").files;
      let fileSizeOK = true;
      for (let index = 0; index < uploadedFile.length; index++) {
        const element = uploadedFile[index];
        let size = element.size;
        if (size > 50000000) {
          fileSizeOK = false;
        }
      }
      if (fileSizeOK) {
        if (uploadedFile.length) {
          let fileArrayloc = Object.assign(uploadedFile[0]);
          setfileArray(Object.assign(uploadedFile[0]));
          setfinalSelectedFile(generateFilePreview(fileArrayloc));
        }
      } else {
        alert("You can not select filesize greater than 50 MB");
      }
    }, 500);
  };
  function setSessionHeaders() {
    let sessionKey = "";
    let accountdata = props.state.institute_data;
    let testdata = props.state.test_data;
    let studentdata = props.state.student_data;
    if (
      studentdata != undefined &&
      testdata != undefined &&
      accountdata != undefined
    ) {
      sessionKey =
        studentdata.userid +
        ":" +
        testdata.test_id +
        ":" +
        accountdata.account_id +
        ":" +
        props.state.session_key;
      sessionKey = btoa(sessionKey);

      return sessionKey;
    }
  }
  const generateFilePreview = (file) => {
    var obj = {
      name: file.name,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      webkitRelativePath: file.webkitRelativePath,
      size: file.size,
      type: file.type,
    };
    return obj;
  };
  const uploadFile = async () => {
    if (Object.entries(finalSelectedFile).length) {
      let request = {
        url: `/v1/examPanel/getImageUploadUrl`,
        headers: {
          "X-Device-Key": localStorage.getItem("identityKey"),
          "X-Session-Key": setSessionHeaders(),
        },
        data: finalSelectedFile,
      };
      await api.postAuth(request).then((response) => {
        let uploadFileResponse = response.data;
        var temp = [];
        if (uploadFileResponse.validate) {
          var file_ques_key = "file_" + props.activeQuesData.ques_id;
          if (
            question_fileV2_mapping &&
            question_fileV2_mapping.hasOwnProperty(file_ques_key)
          ) {
            temp = question_fileV2_mapping[file_ques_key];
            temp.push(
              uploadFileResponse.file_id + "#" + uploadFileResponse.source_url
            );
            question_fileV2_mapping[file_ques_key] = temp;
          } else {
            temp = [];
            temp.push(
              uploadFileResponse.file_id + "#" + uploadFileResponse.source_url
            );
            question_fileV2_mapping[file_ques_key] = temp;
          }
          props.setshowUploadBox(false);
          uploadFileToAWS(uploadFileResponse);
          props.dispatch({
            type: "ADD_QUESTION_FILESV2",
            question_fileV2_mapping: question_fileV2_mapping,
          });
        }
      });
    }
  };

  const uploadFileToAWS = async function (response) {
    let file_path = URL.createObjectURL(fileArray);
    let fileObject = await fetch(file_path).then((r) => r.blob());
    let request = {
      url: response.s3_url,
      headers: {
        "Content-Type": fileArray.type,
      },
      data: fileObject,
    };
    await api.put(request).then((response) => {});
  };

  return (
    <>
      <div className="popup-box">
        <div className="mainboxQuestionUpload">
          <input
            type="file"
            className="form-ctrl"
            onChange={() => fillFile()}
            style={{
              border: "1px  solid #adb5bd",
              padding: "2px",
              fontSize: "12px",
              fontWeight: "bold",
              borderRadius: "2px",
            }}
            id="uploadFileControl"
            name="uploadFileBox"
            accept=".jpg,.docx,.doc,.ppt,.pptx,.xls,.xlxs,.png,.jpeg,.pdf"
          />
          <div className="text-left">
            <span className="text-danger" style={{ fontSize: "12px" }}>
              Please select file to upload
            </span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontSize: "14px", color: "#6c757d" }}>
              Format Supported : docx, doc, ppt, pptx, xls, xlsx, png, jpeg,
              jpg, pdf
            </span>
            <div
              style={{
                width: "65%",
                display: "flex",
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <span style={{ fontSize: "14px", color: "#6c757d" }}>
                Max Size: 50MB
              </span>
            </div>
          </div>

          <div style={{ marginTop: "5px" }}>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => uploadFile()}
            >
              Upload
            </button>
            <button
              style={{ marginLeft: "8px" }}
              className="btn btn-light btn-sm"
              onClick={() => closeUploadBox()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

// export default FileUpload;
const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(FileUpload);
