import React, { useEffect, useState } from "react";
import api from "../../api";
import "./home.scss";
import Loader from "../../components/loader";
import g1 from "../../assets/icons/g1.svg";
import g2 from "../../assets/icons/g2.svg";
import g3 from "../../assets/icons/g3.svg";
import g4 from "../../assets/icons/g4.svg";
import g5 from "../../assets/icons/g5.svg";
import g6 from "../../assets/icons/g6.svg";
import r1 from "../../assets/icons/r1.svg";
import r2 from "../../assets/icons/r2.png";
import el_user from "../../assets/icons/el_user.svg";
import dangImg from "../../assets/icons/dangImg.png";
import antidesign from "../../assets/icons/antidesign.png";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Buffer } from "buffer";

const Home = (props) => {
  let base64ToString = Buffer.from(
    `${window.location.href.split("/").pop()}`,
    "base64"
  ).toString();
  const [test_duration, setTestDuration] = useState("0d 0 h 0 m");
  const [test_status_message, setTestStatusMsg] = useState("");
  const [sessionLoading, setSessionLoading] = useState(false);
  const [show_live_class_btn, setShow_live_class_btn] = useState(false);
  const [live_class_url, setLive_class_url] = useState("");
  const [show_proceed, setShow_proceed] = useState(false);
  const [test_type, setTesttype] = useState("");
  const [labelColor, setLabelColor] = useState("");
  const [is_preview, setIs_preview] = useState(false);
  const [authDetail, setAuthDetail] = useState({
    auth_token: "",
    loading: true,
    showError: false,
    test_start_date: new Date(),
    test_end_date: new Date(),
  });

  function formatDate(date) {
    var monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      " " +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2)
    );
  }

  useEffect(async () => {
    localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    await props.dispatch({ type: "LOGININTIAL" });
    auth_request();
  }, []);

  const XdeviceKey = () => {
    if (localStorage.getItem("identityKey") == undefined) {
      let identityKey = Math.random().toString(36).slice(2);
      localStorage.setItem("identityKey", identityKey);
      return identityKey;
    } else {
      return localStorage.getItem("identityKey");
    }
  };

  const formatAMPM = (data) => {
    let dateloc = new Date(data);
    let hours = dateloc.getHours();
    let minutes = dateloc.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    const strTime = `${hours}:${minutes} ${ampm}`;

    return strTime;
  };

  const formateDate = (data) => {
    let arr = data.split(" ");
    arr.pop();
    return arr.join("-");
  };

  const auth_request = () => {
    setAuthDetail({ loading: true });
    let request = {
      url: `/v1/examPanel/auth/${window.location.href.split("/").pop()}`,
      headers: {
        "X-Device-Info": btoa(JSON.stringify(window.navigator.userAgent)),
        "X-Device-Key": XdeviceKey(),
      },
    };

    api
      .getAuth(request)
      .then((response) => {
        if (response.data.validate == true) {
          let data = response.data.data;
          props.dispatch({
            type: "AUTHORIZATION",
            institute_data: data.institute_data,
            test_language: data.test_languages,
            student_data: data.student_data,
            test_data: data.test_data,
            authorised: data.authorised,
            session_key: data.session,
            attempt_data: data.attempt_data,
          });
          if (data.student_data.is_preview !== undefined) {
            setIs_preview(data.student_data.is_preview == 1 ? true : false);
          }
          const test_start_date = new Date(
            data.test_data.test_start_timestamp * 1000
          );
          const test_end_date = new Date(
            data.test_data.test_end_timestamp * 1000
          );
          setAuthDetail({ test_start_date: test_start_date });
          setAuthDetail({ test_end_date: formatDate(test_end_date) });
          if (
            data.test_data.procturing_link &&
            data.test_data.procturing_link !== ""
          ) {
            setShow_live_class_btn(true);
            setLive_class_url(data.test_data.procturing_link);
          }

          switch (data.test_data.test_type_id) {
            case 1:
              setTesttype("Mock Test");
              setAuthDetail({
                days: Math.floor(parseInt(data.test_data.duration) / 1440),
              });
              setTestDuration(
                Math.floor(parseInt(data.test_data.duration) / 1440) +
                  "d" +
                  " " +
                  parseInt((parseInt(data.test_data.duration) % 1440) / 60) +
                  "h" +
                  " " +
                  Math.round(parseInt(data.test_data.duration) % 60) +
                  "m"
              );
              break;
            case 2:
              setTesttype("Practice Test");
              if (
                data.test_data.duration == 0 ||
                data.test_data.duration == ""
              ) {
                setTestDuration("No Restriction");
              } else {
                setAuthDetail({
                  days: Math.floor(parseInt(data.test_data.duration) / 1440),
                });
                setTestDuration(
                  Math.floor(parseInt(data.test_data.duration) / 1440) +
                    "d" +
                    " " +
                    parseInt((parseInt(data.test_data.duration) % 1440) / 60) +
                    "h" +
                    " " +
                    Math.round(parseInt(data.test_data.duration) % 60) +
                    "m"
                );
              }
              setAuthDetail({ test_start_date: 0 });
              setAuthDetail({ test_end_date: 0 });
              break;
            case 3:
              setTesttype("Classroom Exam");
              setAuthDetail({
                days: Math.floor(parseInt(data.test_data.duration) / 1440),
              });
              setTestDuration(
                Math.floor(parseInt(data.test_data.duration) / 1440) +
                  " d " +
                  " " +
                  Math.floor(
                    (parseInt(data.test_data.duration) -
                      authDetail.days * 1440) /
                      60
                  ) +
                  "h" +
                  " " +
                  Math.round(parseInt(data.test_data.duration) % 60) +
                  "m"
              );
            case 4:
              setTesttype("Online Exam");
              setAuthDetail({
                days: Math.floor(parseInt(data.test_data.duration) / 1440),
              });
              setTestDuration(
                Math.floor(parseInt(data.test_data.duration) / 1440) +
                  "d" +
                  " " +
                  parseInt((parseInt(data.test_data.duration) % 1440) / 60) +
                  "h" +
                  " " +
                  Math.round(parseInt(data.test_data.duration) % 60) +
                  "m"
              );
              break;
          }
          switch (data.test_status) {
            case "ACTIVE":
              setLabelColor("green");
              setTestStatusMsg("");
              setShow_proceed(true);
              break;
            case "UPCOMING":
              setLabelColor("blue");
              setTestStatusMsg("This is an upcoming test");
              setShow_proceed(true);
              break;
            case "EXPIRED":
              setLabelColor("red");
              setTestStatusMsg("This test is already expired!");
              break;

            case "EXHAUSTED":
              setLabelColor("red");
              setTestStatusMsg(
                data.test_data.test_type_id != 2
                  ? "You have already attempted this test."
                  : "You have consumed the attempt limit for this test."
              );
              break;

            case "QUOTA_EXHAUSTED":
              setLabelColor("red");
              setTestStatusMsg(
                "Test limit quota has been exhausted, Please contact institute for more details."
              );
              break;

            case "INVALID":
            case "DISABLED":
            case "NOT_PUBLISHED":
              break;
          }
        }

        setAuthDetail({ loading: false });
      })
      .catch((err) => {
        setAuthDetail({ loading: false });
        setAuthDetail({ showError: true });
      });
  };

  const changeSession = (userId, instId) => {
    setSessionLoading(true);
    let request = {
      url: `/v1/examPanel/changeSession/${userId}/${instId}`,
      headers: {
        "X-Device-Info": btoa(JSON.stringify(window.navigator.userAgent)),
        "X-Device-Key": XdeviceKey(),
      },
    };
    api.getAuth(request).then(
      (response) => {
        setSessionLoading(false);
        if (response.data.validate == true) {
          props.dispatch({
            type: "CHANGES_SESSION",
            session_key: response.data.data.session,
          });
        }
      },
      (error) => {
        setAuthDetail({ apiError: false });
        setAuthDetail({ loading: false });
        setAuthDetail({ showError: false });
      }
    );
  };
  return (
    <>
      {authDetail.loading && (
        <div>
          <Loader />
        </div>
      )}
      {!props.state.authorised && (
        <div
          className="col-md-6  pt-4 pb-4 d-flex"
          style={{ maxWidth: "400px", margin: "auto" }}
        >
          <div className="m-auto text-center">
            <p className="lead text-bold">Authentication Failed</p>
            <p className="text-small text-gray">
              You are not authorised to access the resource
            </p>
          </div>
        </div>
      )}

      {!authDetail.showError && !authDetail.loading && (
        <div className="s1-v">
          <div className="header">
            <div>
              <a href="#">
                <button>Online test</button>
              </a>
            </div>
            <p>
              {props.state.institute_data.account_name !== ""
                ? props.state.institute_data.account_name
                : props.state.client_name}
            </p>
            {is_preview && (
              <a>
                <button>Test Preview</button>
              </a>
            )}
          </div>

          <div className="height">
            <div>
              {test_status_message && (
                <p
                  class="red-alert"
                  style={{
                    backgroundColor: `${
                      labelColor == "red"
                        ? "red"
                        : labelColor == "green"
                        ? "green"
                        : "blue"
                    }`,
                    color: `${labelColor == "red" ? "white" : "white"}`,
                  }}
                >
                  {test_status_message}
                </p>
              )}
              <span
                className={
                  test_type == "Practice Test"
                    ? "tag prectice"
                    : test_type == "Mock Test"
                    ? "tag mock"
                    : "tag online"
                }
              >
                {test_type}
              </span>

              <h1>{props.state.test_data.test_name}</h1>
              <div className="line"></div>
              <div className="flex-300">
                <div>
                  <img src={g1} />
                  <div>
                    <label>Questions</label>
                    <h6>{props.state.test_data.total_question}</h6>
                  </div>
                </div>
                <div>
                  <img src={g3} />
                  <div>
                    <label>Max. Marks</label>
                    <h6>{props.state.test_data.total_marks}</h6>
                  </div>
                </div>
                <div>
                  <img src={g5} />
                  <div>
                    <label>Duration</label>
                    <h6>{test_duration}</h6>
                  </div>
                </div>
                <div>
                  <img src={g2} />
                  <div>
                    <label>Difficulty</label>
                    <h6>{props.state.test_data.difficulty_title}</h6>
                  </div>
                </div>
                <div>
                  {props.state.test_data.test_type_id != 2 && (
                    <div className="flex">
                      <img src={g4} />

                      <label>Start Date & Time </label>
                    </div>
                  )}
                  {props.state.test_data.test_type_id != 2 && (
                    <div className="flex dt">
                      <img src={r1} />

                      <p>
                        {formateDate(
                          props.state.test_data.test_start_formatted
                        )}
                      </p>
                    </div>
                  )}
                  {props.state.test_data.test_type_id != 2 && (
                    <div className="flex dt">
                      <img src={r2} />

                      <p>
                        {formatAMPM(props.state.test_data.test_start_formatted)}
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  {props.state.test_data.test_type_id != 2 && (
                    <div className="flex">
                      <img src={g6} />

                      <label>End Date & Time </label>
                    </div>
                  )}
                  {props.state.test_data.test_type_id != 2 && (
                    <div className="flex dt">
                      <img src={r1} />

                      <p>
                        {formateDate(props.state.test_data.test_end_formatted)}
                      </p>
                    </div>
                  )}
                  {props.state.test_data.test_type_id != 2 && (
                    <div className="flex dt">
                      <img src={r2} />
                      <p>
                        {" "}
                        {formatAMPM(props.state.test_data.test_end_formatted)}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {props.state.authorised && props.state.session_key != false && (
              <div className="name_details">
                <img src={el_user} />
                <div className="br"></div>
                <p>Signed in as</p>
                {props.state.student_data.userid == 1234567890 ? (
                  <span>Welcome Student</span>
                ) : (
                  <span>{props.state.student_data.name}</span>
                )}
                <div className="br"></div>
                {props.state.student_data.userid != 1234567890 && (
                  <p>Student ID</p>
                )}
                {props.state.student_data.userid != 1234567890 && (
                  <span>{props.state.student_data.student_disp_id} </span>
                )}
              </div>
            )}
            {!props.state.session_key && props.state.authorised && (
              <div class="name_details">
                <h1>
                  <img src={dangImg} />
                  Multiple Session found
                </h1>
                <h6>Close other sessions and continue here?</h6>
                {sessionLoading ? (
                  <button> Loading Session...</button>
                ) : (
                  <button
                    onClick={() =>
                      changeSession(
                        props.state.student_data.userid,
                        props.state.institute_data.institute_id
                      )
                    }
                  >
                    {" "}
                    Use Here
                  </button>
                )}
              </div>
            )}
          </div>
          {props.state.authorised &&
            show_proceed &&
            props.state.session_key != false && (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "center",
                  marginTop: "32px",
                }}
              >
                <div style={{ marginRight: "15px" }}>
                  {show_live_class_btn && (
                    <button className="liveclassbutton">
                      {" "}
                      <a href={live_class_url} target="_blank">
                        {" "}
                        Start Live class
                      </a>
                    </button>
                  )}
                  {show_live_class_btn && (
                    <p className="liveclasstext">
                      {" "}
                      <img height={20} width={20} src={antidesign} /> You must
                      join live class during your test.
                    </p>
                  )}
                </div>
                {props.state.student_data.userid == 1234567890 &&
                base64ToString.split(":").pop() == "password" ? (
                  <Link to="/registration" style={{ textDecoration: "none" }}>
                    <button className="button_format">Proceed</button>
                  </Link>
                ) : (
                  <Link to="/instructions" style={{ textDecoration: "none" }}>
                    <button className="button_format">Proceed</button>
                  </Link>
                )}
              </div>
            )}
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(Home);
