import React, { useEffect, useState } from "react";
import "./optionFormat.scss";
import { connect } from "react-redux";

const OptionFormat = (props) => {
  const alphabates = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "i",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
    "u",
    "v",
    "w",
    "x",
    "y",
    "z",
  ];
  const [multiLanguagesQuesList, setMultiLanguagesQuesList] = useState([]);
  const [indexNum, setIndexNum] = useState(0);
  const [answerJson, setAnswerJson] = useState([]);
  const [currAns, setCurrAns] = useState([]);
  const [subjectiveData, setSubjectData] = useState("");

  var regex = /(<([^>]+)>)/gi;
  useEffect(() => {
    if (props.state.answers_json || !props.state.answers_json == undefined) {
      let Ind = props.state.activeQuesInd;
      setCurrAns([]);
      setIndexNum(props.state.activeQuesInd);
      setAnswerJson(props.state.answers_json);
      if (
        props.state.answers_json[Ind] !== "-1" &&
        props.state.answers_json[Ind] !== undefined
      ) {
        let newArr = props.state.answers_json[Ind].split(",");
        setCurrAns([...newArr]);
      }
      if (props.activeQuesData.ques_type_id == 2) {
        let index = props.state.answers_json[Ind].split("_").pop();
        if (props.state.subjective_answer[index] == -1) {
          setSubjectData("");
        } else {
          setSubjectData(props.state.subjective_answer[index]);
        }
      }
    }
  }, [
    props.activeQuesData,
    props.state.subjective_answer,
    props.state.answers_json,
  ]);

  const checkInputType = (event) => {
    const intg = /^[0-9-+$#@!*&^%_{}:";<>?/~()]*$/;
    const dec = /^[0-9-+$#@!*&^%_{}.:";<>?/~()]*$/;
    if (props.activeQuesData.ans_data_type == "int") {
      if (event === "" || intg.test(event)) {
        let subjectdata = props.state.subjective_answer;

        setSubjectData(btoa(event));
        subjectdata[
          parseInt(
            props.state.answers_json[props.state.activeQuesInd].split("_").pop()
          )
        ] = btoa(event);
        props.dispatch({
          type: "SUBJECTIVE_ANSWERS",
          subjective_answer: [...subjectdata],
        });
      }
    } else if (props.activeQuesData.ans_data_type == "decimal") {
      if (event === "" || dec.test(event)) {
        let subjectdata = props.state.subjective_answer;
        setSubjectData(btoa(event));
        subjectdata[
          parseInt(
            props.state.answers_json[props.state.activeQuesInd].split("_").pop()
          )
        ] = btoa(event);
        props.dispatch({
          type: "SUBJECTIVE_ANSWERS",
          subjective_answer: [...subjectdata],
        });
      }
    } else if (props.activeQuesData.ans_data_type == "text") {
      if (props.activeQuesData.is_ans_limit == 2) {
        limitByWords(props.activeQuesData.ans_limit, event);
      } else {
        let subjectdata = props.state.subjective_answer;
        setSubjectData(btoa(event));
        subjectdata[
          parseInt(
            props.state.answers_json[props.state.activeQuesInd].split("_").pop()
          )
        ] = btoa(event);
        props.dispatch({
          type: "SUBJECTIVE_ANSWERS",
          subjective_answer: [...subjectdata],
        });
      }
    }
  };

  function getCheckedVal(index2, index) {
    let a = currAns.find((item) => item.indexOf(index2 + "=") != -1);
    return a ? a.includes(alphabates[index]) : false;
  }

  function decodeOption(opt) {
    return decodeURIComponent(escape(window.atob(opt)));
  }

  function getChar(index) {
    return "(" + String.fromCharCode(index) + ")";
  }

  const SelectRadio = (In) => {
    if (props.activeQuesData.ques_type_id === 3) {
      let temp = currAns;
      currAns.includes(alphabates[In])
        ? temp.splice(temp.indexOf(alphabates[In]), 1)
        : temp.push(alphabates[In]);
      setCurrAns(temp);
      let answer_json = answerJson;
      temp.sort();
      answer_json[indexNum] = temp.length > 0 ? temp.join(",") : "-1";
      setAnswerJson([...answer_json]);
      props.dispatch({ type: "SET_ANSWER", answers_json: answerJson });
    } else if (props.activeQuesData.ques_type_id === 1) {
      let temp = [];
      temp[0] = alphabates[In];
      setCurrAns([...temp]);
      let answer_json = answerJson;
      answer_json[indexNum] = temp.length > 0 ? temp.join(",") : "-1";
      setAnswerJson([...answer_json]);
      props.dispatch({ type: "SET_ANSWER", answers_json: answerJson });
    } else if (props.activeQuesData.ques_type_id === 5) {
      let temp = [];
      temp[0] = alphabates[In];
      setCurrAns([...temp]);
      let answer_json = answerJson;
      answer_json[indexNum] = temp.length > 0 ? temp.join(",") : "-1";
      setAnswerJson([...answer_json]);
      props.dispatch({ type: "SET_ANSWER", answers_json: answerJson });
    }
  };

  function limitByWords(allowedLimit, event) {
    let words = atob(subjectiveData);
    let wordArr = words.split(" ");
    wordArr = wordArr.filter((obj) => obj !== "");
    if (wordArr.length > allowedLimit) {
      wordArr.splice(allowedLimit, wordArr.length);
      setSubjectData(btoa(wordArr.join(" ")));
    } else {
      let subjectdata = props.state.subjective_answer;
      setSubjectData(btoa(event));
      subjectdata[
        parseInt(
          props.state.answers_json[props.state.activeQuesInd].split("_").pop()
        )
      ] = btoa(event);
      props.dispatch({
        type: "SUBJECTIVE_ANSWERS",
        subjective_answer: [...subjectdata],
      });
    }
  }

  function markMatrixAnswer(index2, index) {
    let a = currAns.findIndex((item) => item.includes(index2 + "="));
    if (a > -1) {
      let temp = currAns[a].split("=").pop().split("|");
      temp.includes(alphabates[index])
        ? temp.splice(temp.indexOf(alphabates[index]), 1)
        : temp.push(alphabates[index]);
      let sorttemp = temp.sort();
      if (sorttemp.length === 0) {
        currAns.splice(a, 1);
      } else if (sorttemp.length > 0) {
        currAns[a] = index2 + "=" + sorttemp.join("|");
      }
    } else {
      currAns.push(index2 + "=" + alphabates[index]);
    }
    let answer_json = answerJson;
    currAns.sort();
    answer_json[indexNum] = currAns.length > 0 ? currAns.join(",") : "-1";
    setAnswerJson([...answer_json]);
    props.dispatch({ type: "SET_ANSWER", answers_json: [...answer_json] });
  }

  function markMatchAnswer(index2, index) {
    let a = currAns.findIndex((item) => item.includes(index2 + "="));
    if (a > -1) {
      let temp = currAns[a];
      let dummy = temp.split("=");
      if (dummy[1] == alphabates[index]) {
        currAns.splice(a, 1);
      } else {
        dummy[1] = alphabates[index];
        currAns[a] = dummy.join("=");
      }
    } else {
      currAns.push(index2 + "=" + alphabates[index]);
    }
    let answer_json = answerJson;
    currAns.sort();
    answer_json[indexNum] = currAns.length > 0 ? currAns.join(",") : "-1";
    setAnswerJson([...answer_json]);
    props.dispatch({ type: "SET_ANSWER", answers_json: [...answer_json] });
  }

  function is_checked(index2, index) {
    return true;
  }

  return (
    <>
      {/* Match the Following & Match Matrix */}
      {(props.activeQuesData.ques_type_id == 6 ||
        props.activeQuesData.ques_type_id == 7) && (
        <div className="both-side-options" style={{ margin: "2em 0" }}>
          {props.activeQuesData.options.map((item, index) => (
            <div className="option-box">
              <div className="form-group left-side-option">
                <div>
                  <label className="option-label">
                    <span className="option-no">{"(" + (index + 1) + ")"}</span>
                    {props.state.test_data.is_multi_lingual != 1 && (
                      <label
                        dangerouslySetInnerHTML={{
                          __html: decodeOption(item.answer_option),
                        }}
                      />
                    )}
                    {props.state.test_data.is_multi_lingual == 1 &&
                      !multiLanguagesQuesList.length && (
                        <label
                          dangerouslySetInnerHTML={{
                            __html: decodeOption(item.answer_option),
                          }}
                        />
                      )}
                  </label>
                </div>
              </div>

              <div className="form-group right-side-option">
                <div>
                  <label className="option-label">
                    <span className="option-no">{getChar(index + 97)}</span>
                    {props.state.test_data.is_multi_lingual != 1 &&
                      (item.right_option_raw == "" ? (
                        <span className="option-text w-space-pre">
                          {decodeOption(item.right_option_raw)}
                        </span>
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: decodeOption(item.right_option_raw),
                          }}
                        />
                      ))}

                    {props.state.test_data.is_multi_lingual == 1 &&
                      !multiLanguagesQuesList.length &&
                      (item.right_option_raw == "" ? (
                        <span className="option-text w-space-pre">
                          {decodeOption(item.right_option_raw)}
                        </span>
                      ) : (
                        <label
                          dangerouslySetInnerHTML={{
                            __html: decodeOption(item.right_option_raw),
                          }}
                        />
                      ))}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {props.activeQuesData.ques_type_id != 6 &&
        props.activeQuesData.ques_type_id != 7 && (
          <div className="one-side-options" style={{ margin: "2em 0" }}>
            {/* Single Choice Options  */}
            {props.activeQuesData.ques_type_id === 1 && (
              <div className="single-choice-options">
                {props.activeQuesData.options.map((item, ind) => (
                  <div className="form-group option-box">
                    <input
                      type="radio"
                      checked={currAns[0] === alphabates[ind]}
                      onChange={() => SelectRadio(ind)}
                    ></input>
                    <label style={{ display: "flex", alignItems: "center" }}>
                      <span className="option-label">{getChar(ind + 97)}</span>
                      {
                        <label
                          className="removepmargin"
                          dangerouslySetInnerHTML={{
                            __html: decodeOption(item.answer_option),
                          }}
                        />
                      }
                    </label>
                  </div>
                ))}
              </div>
            )}

            {/* Multiple Choice Options  */}

            {props.activeQuesData.ques_type_id == 3 && (
              <div className="multiple-choice-options">
                {props.activeQuesData.options.map((item, index) => (
                  <div className="form-group option-box">
                    <input
                      type="checkbox"
                      checked={currAns.includes(alphabates[index])}
                      onChange={() => SelectRadio(index)}
                    />
                    <span className="option-label">{getChar(index + 97)}</span>
                    {
                      <label
                        className="removepmargin"
                        dangerouslySetInnerHTML={{
                          __html: decodeOption(item.answer_option),
                        }}
                      />
                    }
                    {/* <label></label> */}
                  </div>
                ))}
              </div>
            )}

            {/* True False Options */}
            {props.activeQuesData.ques_type_id == 5 && (
              <div className="true-false-options">
                <div className="form-group option-box">
                  <input
                    type="radio"
                    checked={currAns[0] === alphabates[0]}
                    onChange={() => SelectRadio(0)}
                  />
                  <label className="option-label">True</label>
                </div>
                <div className="form-group option-box">
                  <input
                    type="radio"
                    checked={currAns[0] === alphabates[1]}
                    onChange={() => SelectRadio(1)}
                  />
                  <label className="option-label">False</label>
                </div>
              </div>
            )}

            {/* Text Question Area */}

            {props.activeQuesData.ques_type_id == 2 && (
              <div className="passage-options">
                <div className="form-group option-box">
                  {/* for text */}
                  {props.activeQuesData.ans_data_type == "text" && (
                    <input
                      name=""
                      placeholder="Write your answer here..."
                      value={atob(subjectiveData)}
                      maxLength={
                        props.activeQuesData.is_ans_limit == 1
                          ? props.activeQuesData.ans_limit
                          : -1
                      }
                      onChange={(e) => checkInputType(e.target.value)}
                      className="form-control"
                    ></input>
                  )}
                  {/* for integer */}
                  {props.activeQuesData.ans_data_type == "int" && (
                    <input
                      name=""
                      placeholder="Write your answer here..."
                      value={atob(subjectiveData)}
                      maxLength={
                        props.activeQuesData.is_ans_limit == 1
                          ? props.activeQuesData.ans_limit
                          : -1
                      }
                      onChange={(e) => checkInputType(e.target.value)}
                      className="form-control"
                    ></input>
                  )}
                  {/* for decimal  */}
                  {props.activeQuesData.ans_data_type == "decimal" && (
                    <input
                      name=""
                      placeholder="Write your answer here..."
                      value={atob(subjectiveData ? subjectiveData : '')}
                      maxLength={
                        props.activeQuesData.is_ans_limit == 1
                          ? props.activeQuesData.ans_limit
                          : -1
                      }
                      onChange={(e) => checkInputType(e.target.value)}
                      className="form-control"
                    ></input>
                    
                  )}

                </div>
              </div>
            )}
          </div>
        )}

      {/* <!-- Answer Options for Match Matrix and Match the Following -->   */}
      {(props.activeQuesData.ques_type_id == 6 ||
        props.activeQuesData.ques_type_id == 7) && (
        <div className="answer-options-container">
          {props.activeQuesData.options.map((item, index2) => (
            <div className="answer-option-box">
              {item.answer_option != "" && (
                <div>
                  <span className="option-no"> {index2 + 1}</span>
                  {props.activeQuesData.options.map((item, index) => {
                    let checked_val = getCheckedVal(index2, index);
                    return (
                      <span className="option-select">
                        {item.answer_option != "" && (
                          <div>
                            {props.activeQuesData.ques_type_id == 6 && (
                              <input
                                type="checkbox"
                                checked={checked_val}
                                onChange={() => markMatrixAnswer(index2, index)}
                              />
                            )}

                            {props.activeQuesData.ques_type_id == 7 && (
                              <input
                                type="radio"
                                checked={checked_val}
                                onChange={() => markMatchAnswer(index2, index)}
                              />
                            )}
                            <label>{getChar(index + 97)}</label>
                          </div>
                        )}
                      </span>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(OptionFormat);
