import React, { useEffect, useState } from "react";
import "./result.css";
import Logo from "../../assets/images/logo.svg";
import el_user from "../../assets/icons/el_user.svg";
import CurrentResult from "../../assets/icons/currectresult.png";
import Award from "../../assets/icons/award.png";
import Percentage from "../../assets/icons/percentage.png";
import Spedometer from "../../assets/icons/spedometer.png";
import SubjectWiseReport from "./subjectWiseReport";
import QuestionWiseReport from "./QuestionWiseReport";
import Symmary from "./Summary";
import api from "../../api";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Result = (props) => {
  const [selectReport, setSelectReport] = useState(0);
  const [averageReport, setAverageReport] = useState({});
  const [questionWiseDistribution, setQuestionWiseDistribution] = useState({});
  const [subjectWiseDistribution, setSubjectWiseDistribution] = useState({});
  const [testReport, setTestReport] = useState({});
  const [topperreport, setTopperReport] = useState({});

  const ganarateAuthtoken = () => {
    let authToken =
      props.test_data.account_id +
      ":" +
      props.student_data.userid +
      ":" +
      props.test_data.test_id +
      ":-1";
    authToken = btoa(authToken);
    authToken = authToken.replaceAll("=", "$");
    return authToken;
  };

  function padTo2Digits(num) {
    return num.toString().padStart(1, "0");
  }

  function convertMsToTime(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    let timeData = `${padTo2Digits(hours)}:${padTo2Digits(
      minutes
    )}:${padTo2Digits(seconds)}`;

    return timeData.split(":");
  }

  const calcTimeTaken = (time) => {
    if (Math.floor(time / 60) > 0) {
      return Math.floor(time / 60) + " min " + (time % 60) + "sec";
    } else {
      return (time % 60) + "sec";
    }
  };

  function format(inputDate) {
    let dat = new Date(inputDate);
    let date, month, year;
    date = dat.getDate();
    month = dat.getMonth() + 1;
    year = dat.getFullYear();
    date = date.toString().padStart(2, "0");

    month = month.toString().padStart(2, "0");

    return `${date}/${month}/${year}`;
  }

  const formatAMPM = (data) => {
    let dateloc = new Date(data);
    let hours = dateloc.getHours();
    let minutes = dateloc.getMinutes();
    let seconds = dateloc.getSeconds();
    const ampm = hours >= 12 ? "pm" : "am";

    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    const strTime = `${hours}:${minutes}: ${seconds} ${ampm}`;

    return strTime;
  };

  function setSessionHeaders() {
    let sessionKey = "";
    let accountdata = props.institute_data;
    let testdata = props.test_data;
    let studentdata = props.student_data;
    if (
      studentdata != undefined &&
      testdata != undefined &&
      accountdata != undefined
    ) {
      sessionKey =
        studentdata.userid +
        ":" +
        testdata.test_id +
        ":" +
        accountdata.account_id +
        ":" +
        props.session_key;
      sessionKey = btoa(sessionKey);

      return sessionKey;
    }
  }
  useEffect(() => {
    ResultApi();
  }, []);

  const calcPercent = (total_score, total_marks) => {
    return ((total_score / total_marks) * 100).toFixed(2);

    /*  return (((this.summary.total_score / this.summary.total_marks)) * 100).toFixed(2); */
  };
  const ResultApi = () => {
    let request = {
      url: `/result/${props.institute_data.institute_id}`,
      headers: {
        "X-Device-Key": localStorage.getItem("identityKey"),
        "X-Session-Key": setSessionHeaders(),
      },
    };
    api.getAuthresult(request).then((response) => {
      setAverageReport(response.data.data.average_report);
      setQuestionWiseDistribution(
        response.data.data.question_wise_distribution
      );
      setSubjectWiseDistribution(response.data.data.subject_wise_distribution);
      setTestReport(response.data.data.test_report);
      setTopperReport(response.data.data.topper_report);
    });
  };

  return (
    <>
      <div className="resultcontainer">
        <div className="topHeader">
          <img height="58px" width="120px" src={Logo} />
        </div>
        <div className="borderLine"></div>
        <div className="subjectHeader">
          <div style={{ width: "70%" }}>
            <p className="subjectNamediv">{props?.test_data?.test_name}</p>
          </div>
          <div>
            <div>
              <p className="signInTitle">Sign in as</p>
              <p className="signValue">{props?.student_data?.student_name}</p>
            </div>
            <div>
              <p className="signInTitle">Student ID</p>
              <p className="signValue">
                {" "}
                {props?.student_data?.student_disp_id}
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            paddingLeft: "11.813rem",
            paddingTop: "3.813rem",
            paddingRight: "10.688rem",
            paddingBottom: "4.9rem",
          }}
        >
          <div className="resultSection">
            <div style={{ width: "75%", padding: "26px" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p>Your Score </p>
                <p>
                  Total Questions :{" "}
                  <span>
                    {testReport.right_ques_count +
                      testReport.wrong_ques_count +
                      testReport.left_ques_count}
                  </span>
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
                  <div className="progress mx-auto">
                    {calcPercent(
                      testReport.total_score,
                      testReport.total_marks
                    ) *
                      3.6 <
                    180 ? (
                      <span className="progress-left">
                        <span
                          className="progress-bar"
                          style={{
                            transform: `rotate(${
                              calcPercent(
                                testReport.total_score,
                                testReport.total_marks
                              ) * 3.6
                            }deg)`,
                            borderColor: "#1BC19A",
                          }}
                        >
                          {" "}
                        </span>
                      </span>
                    ) : (
                      <span className="progress-left">
                        <span
                          className="progress-bar"
                          style={{
                            transform: "rotate(180deg)",
                            borderColor: "#1BC19A",
                          }}
                        >
                          {" "}
                        </span>
                      </span>
                    )}
                    {calcPercent(
                      testReport.total_score,
                      testReport.total_marks
                    ) *
                      3.6 >
                      180 && (
                      <span className="progress-right">
                        <span
                          className="progress-bar "
                          style={{
                            transform: `rotate(${
                              calcPercent(
                                testReport.total_score,
                                testReport.total_marks
                              ) *
                                3.6 -
                              180
                            }deg)`,
                            borderColor: "#1BC19A",
                          }}
                        ></span>
                      </span>
                    )}

                    <div className="progress-value w-100 h-100 rounded-circle d-flex flex-column align-items-center justify-content-center">
                      <img src={CurrentResult} height="20px" width="20px" />
                      <p style={{ marginBottom: "0px", marginTop: "8px" }}>
                        <span className="obtainpercentage">
                          {testReport?.total_score}
                        </span>
                        <span className="totalpercentage">
                          {" "}
                          / {testReport?.total_marks}
                        </span>
                      </p>
                      <p className="markobtain">Marks Obtained</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
                  <div className="progress mx-auto">
                    {calcPercent(
                      testReport.total_score,
                      testReport.total_marks
                    ) *
                      3.6 <
                    180 ? (
                      <span className="progress-left">
                        <span
                          className="progress-bar"
                          style={{
                            transform: `rotate(${
                              calcPercent(
                                testReport.total_score,
                                testReport.total_marks
                              ) * 3.6
                            }deg)`,
                            borderColor: "#FBB142",
                          }}
                        >
                          {" "}
                        </span>
                      </span>
                    ) : (
                      <span className="progress-left">
                        <span
                          className="progress-bar"
                          style={{
                            transform: "rotate(180deg)",
                            borderColor: "#FBB142",
                          }}
                        >
                          {" "}
                        </span>
                      </span>
                    )}
                    {calcPercent(
                      testReport.total_score,
                      testReport.total_marks
                    ) *
                      3.6 >
                      180 && (
                      <span className="progress-right">
                        <span
                          className="progress-bar "
                          style={{
                            transform: `rotate(${
                              calcPercent(
                                testReport.total_score,
                                testReport.total_marks
                              ) *
                                3.6 -
                              180
                            }deg)`,
                            borderColor: "#FBB142",
                          }}
                        ></span>
                      </span>
                    )}

                    <div className="progress-value w-100 h-100 rounded-circle d-flex flex-column align-items-center justify-content-center">
                      <img src={Percentage} height="20px" width="20px" />
                      <p style={{ marginBottom: "0px", marginTop: "8px" }}>
                        <span
                          className="obtainpercentage"
                          style={{ color: "#FBB142" }}
                        >
                          {" "}
                          {calcPercent(
                            testReport.total_score,
                            testReport.total_marks
                          )}{" "}
                          %
                        </span>
                      </p>
                      <p className="markobtain">Percentage</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 text-center">
                  <div className="progress mx-auto">
                    {calcPercent(
                      testReport.total_attempted_time,
                      props.test_data.duration
                    ) *
                      3.6 <
                    180 ? (
                      <span className="progress-left">
                        <span
                          className="progress-bar"
                          style={{
                            transform: `rotate(${
                              calcPercent(
                                testReport.total_attempted_time,
                                props.test_data.duration
                              ) * 3.6
                            }deg)`,
                            borderColor: "#18689B",
                          }}
                        >
                          {" "}
                        </span>
                      </span>
                    ) : (
                      <span className="progress-left">
                        <span
                          className="progress-bar"
                          style={{
                            transform: "rotate(180deg)",
                            borderColor: "#18689B",
                          }}
                        >
                          {" "}
                        </span>
                      </span>
                    )}
                    {calcPercent(
                      testReport.total_attempted_time,
                      props.test_data.duration
                    ) *
                      3.6 >
                      180 && (
                      <span className="progress-right">
                        <span
                          className="progress-bar "
                          style={{
                            transform: `rotate(${
                              calcPercent(
                                testReport.total_score,
                                testReport.total_marks
                              ) *
                                3.6 -
                              180
                            }deg)`,
                            borderColor: "#18689B",
                          }}
                        ></span>
                      </span>
                    )}

                    <div className="progress-value w-100 h-100 rounded-circle d-flex flex-column align-items-center justify-content-center">
                      <img src={Spedometer} height="20px" width="20px" />
                      <p style={{ marginBottom: "0px", marginTop: "8px" }}>
                        <span
                          className="obtainpercentage"
                          style={{ color: "#18689B" }}
                        >
                          {
                            convertMsToTime(
                              testReport.total_attempted_time * 1000
                            )[0]
                          }
                          <span
                            className="totalpercentage"
                            style={{ color: "#18689B" }}
                          >
                            h{" "}
                          </span>
                          {
                            convertMsToTime(
                              testReport.total_attempted_time * 1000
                            )[1]
                          }
                          <span
                            className="totalpercentage"
                            style={{ color: "#18689B" }}
                          >
                            m
                          </span>{" "}
                          {
                            convertMsToTime(
                              testReport.total_attempted_time * 1000
                            )[2]
                          }
                          <span
                            className="totalpercentage"
                            style={{ color: "#18689B" }}
                          >
                            s
                          </span>
                        </span>
                      </p>
                      <p className="markobtain">Time Taken</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="awardDiv">
              <img src={Award} height="120px" width="120px" />
              <div style={{ paddingTop: "12px" }}>
                <p>
                  {" "}
                  <span style={{ color: " #808CA3" }}>Rank:</span>{" "}
                  <span
                    style={{
                      color: "#323C47",
                      fontWeight: "600",
                      marginLeft: "8px",
                    }}
                  >
                    {" "}
                    {testReport?.rank}
                  </span>{" "}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  width: "100%",
                }}
              >
                <Link
                  to={`/advresult/${ganarateAuthtoken()}`}
                  style={{ textDecoration: "none" }}
                >
                  <button className="buttondiv">View Score report</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ ...state });
export default connect(mapStateToProps)(Result);
