import React from "react";
import "./english.scss";
import notvisited from "../../../assets/icons/not-visited.png";
import notanswered from "../../../assets/icons/not-answered.png";
import answered from "../../../assets/icons/answered.png";
import notanswerreview from "../../../assets/icons/not-answered-review.png";
import answerreview from "../../../assets/icons/answered-review.png";

const EnglishInstruction = (props) => {
  return (
    <>
      <div className="col-md-12 test-instructions">
        <p className="msgTitle">Test Instructions</p>
        <div>{props.instruction}</div>
        <div className="col-md-12 general-instructions">
          <p className="msgTitle">General Instructions</p>
          <section>
            <ol>
              <li className="msgFont">
                The clock will be set at the server. The countdown timer in the
                top right corner of screen will display the remaining time
                available for you to complete the examination. When the timer
                reaches zero, the examination will end by itself. You will not
                be required to end or submit your examination.
              </li>
              <li className="msgFont">
                The Question Palette displayed on the right side of screen will
                show the status of each question using one of the following
                symbols:
                <ul className="legends-listing" type="none">
                  <li className="msgFont">
                    <img src={notvisited} /> You have not visited the question
                    yet.
                  </li>
                  <li className="msgFont">
                    <img src={notanswered} /> You have not answered the
                    question.
                  </li>
                  <li className="msgFont">
                    <img src={answered} /> You have answered the question.
                  </li>
                  <li className="msgFont">
                    <img src={notanswerreview} /> You have NOT answered the
                    question, but have marked the question for review.
                  </li>
                  <li className="msgFont">
                    <img src={answerreview} /> The question(s) "Answered and
                    Marked for Review" will be considered for evaluation.
                  </li>
                </ul>
              </li>
              <li className="msgFont">
                The Marked for Review status for a question simply indicates
                that you would like to look at that question again. If a
                question is answered and Marked for Review, your answer for that
                question will be considered in the evaluation.
              </li>
              <li className="msgFont">
                You can click on the arrow which appears to the left of question
                palette to collapse the question palette thereby maximizing the
                question window. To view the question palette again, you can
                click on which appears on the right side of question window.
              </li>
              <li className="msgFont">
                You can click on your "Profile" image on top right corner of
                your screen to change the language during the exam for entire
                question paper. On clicking of Profile image you will get a
                drop-down to change the question content to the desired
                language.
              </li>
              <li className="msgFont">
                You can click on Scroll Down to navigate to the bottom and
                Scroll Upto navigate to the top of the question area, without
                scrolling.
              </li>
            </ol>
          </section>

          <section>
            <p className="msgFont">
              <b>Navigating to a Question</b>
            </p>
            <ol start="7">
              <li className="msgFont">
                To answer a question, do the following:
                <ol type="a">
                  <li className="msgFont">
                    Click on the question number in the Question Palette at the
                    right of your screen to go to that numbered question
                    directly. Note that using this option does NOT save your
                    answer to the current question.
                  </li>
                  <li className="msgFont">
                    Click on Save & Next to save your answer for the current
                    question and then go to the next question.
                  </li>
                  <li className="msgFont">
                    Click on Mark for Review & Next to save your answer for the
                    current question, mark it for review, and then go to the
                    next question.
                  </li>
                </ol>
              </li>
            </ol>
          </section>

          <section>
            <p className="msgFont">
              <b>Answering a Question</b>
            </p>
            <ol start="8">
              <li className="msgFont">
                Procedure for answering a multiple choice type question:
                <ol type="a">
                  <li className="msgFont">
                    To select your answer, click on the button of one of the
                    options.
                  </li>
                  <li className="msgFont">
                    To deselect your chosen answer, click on the button of the
                    chosen option again or click on the Clear Response button.
                  </li>
                  <li className="msgFont">
                    To change your chosen answer, click on the button of another
                    option.
                  </li>

                  <li className="msgFont">
                    To save your answer, you MUST click on the Save & Next
                    button.
                  </li>
                  <li className="msgFont">
                    To mark the question for review, click on the Mark for
                    Review & Next button. If an answer is selected for a
                    question that is Marked for Review, that answer will be
                    considered in the evaluation.
                  </li>
                </ol>
              </li>
              <li className="msgFont">
                To change your answer to a question that has already been
                answered, first select that question for answering and then
                follow the procedure for answering that type of question.
              </li>
              <li className="msgFont">
                Note that ONLY Questions for which answers are saved or marked
                for review after answering will be considered for evaluation.
              </li>
            </ol>
          </section>

          <section>
            <p className="msgFont">
              <b>Navigating through sections</b>
            </p>
            <ol start="11">
              <li className="msgFont">
                Sections in this question paper are displayed on the top bar of
                the screen. Questions in a section can be viewed by click on the
                section name. The section you are currently viewing is
                highlighted.
              </li>
              <li className="msgFont">
                After click the Save & Next button on the last question for a
                section, you will automatically be taken to the first question
                of the next section.
              </li>
              <li className="msgFont">
                You can shuffle between sections and questions anything during
                the examination as per your convenience only during the time
                stipulated.
              </li>
              <li className="msgFont">
                Candidate can view the corresponding section summary as part of
                the legend that appears in every section above the question
                palette.
              </li>
            </ol>
          </section>
        </div>
      </div>
    </>
  );
};

export default EnglishInstruction;
