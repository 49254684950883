import React, { useState, useEffect } from "react";
import "./instructionpop.css";
import HindiInstruction from "../hindi";
import EnglishInstruction from "../english";
import Kannda from "../kannda";
import Cross from "../../.././assets/icons/cross.png";
import { connect } from "react-redux";

const InstructionPopUp = (props) => {
  const [languageSelected, setLanguageSelected] = useState(1);
  useEffect(() => {
    setLanguageSelected(props.state?.languageSelected ?? 1);
  }, []);
  return (
    <>
      <div className="popup-box" onClick={() => props.setShowInstruction()}>
        <div className="Instructionbox">
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <button onClick={() => props.setShowInstruction()}>
              <img src={Cross} />
            </button>
          </div>
          {!(languageSelected == 2 || languageSelected == 3) && (
            <EnglishInstruction
              instruction={
                props.state.test_data.instruction_content == null
                  ? atob(props.state.test_data.instruction_description).replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )
                  : props.state.test_data.instruction_content[0].description
              }
            />
          )}
          {languageSelected == 2 && (
            <HindiInstruction
              instruction={
                props.state.test_data.instruction_content == null
                  ? atob(props.state.test_data.instruction_description).replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )
                  : props.state.test_data.instruction_content[0].description
              }
            />
          )}
          {languageSelected == 3 && (
            <Kannda
              instruction={
                props.state.test_data.instruction_content == null
                  ? atob(props.state.test_data.instruction_description).replace(
                      /(<([^>]+)>)/gi,
                      ""
                    )
                  : props.state.test_data.instruction_content[0].description
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ state: state });
export default connect(mapStateToProps)(InstructionPopUp);
