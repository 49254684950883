import React from "react";
import "./question_list.css";
import OptionFormat from "../option-format";
import { connect } from "react-redux";
import Media from "../../../components/media";
import cross from "../../../assets/icons/cross.png";
import FileUpload from "../../../components/file_upload";
import Upload from "../../../assets/icons/fa-solid_upload.png";
import bookmark from "../../../assets/icons/bookmark.png";
import bookmarked from "../../../assets/icons/bookmarked.png";
import ImagePreview from "../../../components/imagepreview";
import $ from "jquery";

class QuestionList extends React.Component {
  interval = 0;
  constructor(props) {
    super(props);
    this.state = {
      showUploadBox: false,
      preview: false,
      data: decodeURIComponent(
        escape(window.atob(this.props.activeQuesData.question))
      ),
      previewUrl: localStorage.getItem("taglink"),
    };
  }
  FindInterval = () => {
    this.interval = setInterval(() => {
      let arr = this.props.attempt_time;
      arr[this.props.activeQuesInd] =
        parseInt(arr[this.props.activeQuesInd]) + 1000;
      this.props.dispatch({ type: "ATTEMPT_TIME", attempt_time: arr });
    }, 1000);
  };

  BookmarkedFun = () => {
    let arr = this.props.is_bookmarked;
    if (arr[this.props.activeQuesInd] == 1) {
      arr[this.props.activeQuesInd] = 0;
    } else {
      arr[this.props.activeQuesInd] = 1;
    }
    this.props.dispatch({ type: "SET_BOOKMARKED", is_bookmarked: [...arr] });
  };

  componentDidMount() {
    this.getLinkData();
    this.FindInterval();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.activeQuesData &&
      prevProps.activeQuesData.question !== this.props.activeQuesData.question
    ) {
      this.getLinkData();
    }
    if (
      prevProps.activeQuesData &&
      prevProps.activeQuesData.ques_id !== this.props.activeQuesData.ques_id
    ) {
      clearInterval(this.interval);
      if (
        prevProps.activeQuesData &&
        Object.keys(prevProps.activeQuesData).length > 0
      ) {
        this.FindInterval();
      }
    }
  }

  previewFunction() {
    let url = `https://www.w3schools.com/howto/howto_css_responsive_iframes.asp`;
    this.setState({ previewUrl: url }, () => {
      this.setState({ preview: true });
    });
  }

  getLinkData = () => {
    let doc = document.createElement("div");
    doc.innerHTML = decodeURIComponent(
      escape(window.atob(this.props.activeQuesData.question))
    );
    let links = doc.getElementsByTagName("a");

    this.setState({ data: doc });
    for (let i = 0; i < links.length; i++) {
      // links[i].setAttribute('href', "")
      links[i].setAttribute("target", "_blank");
    }
  };

  removeFile = (arr, ind) => {
    let updateArr = arr;
    let updateObj = this.props.question_fileV2_mapping;
    if (updateArr.length == 1) {
      delete updateObj["file_" + this.props.activeQuesData.ques_id];
    } else {
      updateArr.splice(ind, 1);
      updateObj["file_" + this.props.activeQuesData.ques_id] = updateArr;
    }
    this.props.dispatch({
      type: "ADD_QUESTION_FILESV2",
      question_fileV2_mapping: { ...updateObj },
    });
  };

  QuestionTime = () => {
    let quesAttemptTime = 0;
    if (this.props.activeQuesData.attempt_duration >= 60) {
      quesAttemptTime =
        this.props.activeQuesData.attempt_duration / 60 +
        " h " +
        (this.props.activeQuesData.attempt_duration % 60) +
        " m";
    } else {
      quesAttemptTime = this.props.activeQuesData.attempt_duration + " mins";
    }
    return quesAttemptTime;
  };
  setshowUploadBox = (data) => {
    this.setState({ showUploadBox: data });
  };

  setHidePreview = () => {
    this.setState({ preview: false });
  };
  render() {
    return (
      <>
        {this.state.showUploadBox && (
          <FileUpload
            activeQuesData={this.props.activeQuesData}
            showUploadBox={this.state.showUploadBox}
            setshowUploadBox={this.setshowUploadBox}
          />
        )}
        <div className="col-md-12 ">
          <div className="questionInfodiv">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p className="questionNumLebel">
                Question {this.props.activeQuesInd + 1}
              </p>
              <div className="stateLine"></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {this.props.testPanelData.test_settings
                  .is_show_question_marks == 1 && (
                  <>
                    <p className="markLebel">Marks</p>
                    <p className="postiveMark">
                      + {this.props.activeQuesData.positive_marks}
                    </p>
                    <p className="negativeMark">
                      - {this.props.activeQuesData.negative_marks}
                    </p>
                    <div className="stateLine"></div>
                  </>
                )}

                <p className="questionTypeLabel">Question Type :</p>
                {this.props.activeQuesData.ques_type_id == 1 && (
                  <p className="questionTypeValue">Single Choice</p>
                )}
                {this.props.activeQuesData.ques_type_id == 2 && (
                  <p className="questionTypeValue">Subjective</p>
                )}
                {this.props.activeQuesData.ques_type_id == 3 && (
                  <p className="questionTypeValue">Multiple Choice</p>
                )}
                {this.props.activeQuesData.ques_type_id == 4 && (
                  <p className="questionTypeValue">Fill In The Blanks</p>
                )}
                {this.props.activeQuesData.ques_type_id == 5 && (
                  <p className="questionTypeValue">True / False</p>
                )}
                {this.props.activeQuesData.ques_type_id == 6 && (
                  <p className="questionTypeValue">Matrix</p>
                )}
                {this.props.activeQuesData.ques_type_id == 7 && (
                  <p className="questionTypeValue">Match The Following</p>
                )}
                {this.props.activeQuesData.ques_type_id == 8 && (
                  <p className="questionTypeValue">Passage</p>
                )}
              </div>
            </div>
            {this.props?.testPanelData?.is_bookmark == 1 && (
              <div>
                {this.props?.is_bookmarked[this.props?.activeQuesInd] == 0 ? (
                  <img
                    height="25"
                    width="22"
                    src={bookmark}
                    onClick={() => this.BookmarkedFun()}
                  />
                ) : (
                  <img
                    height="25"
                    width="22"
                    src={bookmarked}
                    onClick={() => this.BookmarkedFun()}
                  />
                )}
              </div>
            )}
          </div>
          <div className="questionshowField">
            {this.props.activeQuesData?.passage && (
              <div className="passage-wrapper">
                <div id="passageWrapper">
                  <div
                    className="d-block m-0"
                    dangerouslySetInnerHTML={{
                      __html: decodeURIComponent(
                        escape(window.atob(this.props.activeQuesData?.passage))
                      ),
                    }}
                  ></div>
                  {this.props.activeQuesData?.passage_media_data && (
                    <div>
                      <Media
                        media={this.props.activeQuesData.passage_media_data}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}

            <div>
              {this.props.activeQuesData.question && (
                <div
                  className="d-block"
                  // style={{ fontSize: "16px", fontWeight: "500", paddingLeft: "10px", paddingTop: "5px", whiteSpace: "pre-wrap" }}
                  dangerouslySetInnerHTML={{
                    __html: this.state.data.outerHTML,
                  }}
                >
                  {/* <p> {this.state.data}</p> */}
                </div>
              )}
              {this.props.activeQuesData?.ques_media_data && (
                <div>
                  <Media media={this.props.activeQuesData.ques_media_data} />
                </div>
              )}
              {this.props.activeQuesData.is_ans_limit == 2 &&
                this.props.activeQuesData.ques_type_id == 2 && (
                  <span style={{ fontWeight: "700", margin: "10px" }}>
                    (Upto {this.props.activeQuesData.ans_limit} word(s) are
                    allowed)
                  </span>
                )}
              {this.props.activeQuesData.is_ans_limit == 1 &&
                this.props.activeQuesData.ques_type_id == 2 && (
                  <span style={{ fontWeight: "700", margin: "10px" }}>
                    (Upto {this.props.activeQuesData.ans_limit} character(s) are
                    allowed)
                  </span>
                )}

              <div style={{ paddingLeft: "10px" }}>
                {Object.keys(this.props.activeQuesData).length > 0 && (
                  <OptionFormat activeQuesData={this.props.activeQuesData} />
                )}
              </div>

              {this.props.activeQuesData.ques_type_id == 2 &&
                this.props.activeQuesData.file_upload_enabled == 1 && (
                  <>
                    <p> Upload your answer file here</p>
                    <div className="form-group option-box">
                      <button
                        className="btn btn-sm"
                        style={{ border: "1px solid" }}
                        title="Upload File"
                        onClick={() => this.setshowUploadBox(true)}
                      >
                        <img src={Upload} height="20px" width="20px" />
                        &nbsp;Upload
                      </button>
                    </div>
                  </>
                )}

              {this.props.activeQuesData.ques_type_id == 2 &&
                this.props.question_fileV2_mapping &&
                this.props.question_fileV2_mapping.hasOwnProperty(
                  "file_" + this.props.activeQuesData.ques_id
                ) &&
                this.props.question_fileV2_mapping[
                  "file_" + this.props.activeQuesData.ques_id
                ].map((item, index) => (
                  <div>
                    <p
                      style={{
                        padding: "5px",
                        margin: "5px",
                        background: "#F0F8FF",
                        color: "#DC143C",
                        font: "14px",
                      }}
                    >
                      {item.split("#").pop()}{" "}
                      <img
                        src={cross}
                        onClick={() =>
                          this.removeFile(
                            this.props.question_fileV2_mapping[
                              "file_" + this.props.activeQuesData.ques_id
                            ],
                            index
                          )
                        }
                      />
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({ ...state });
export default connect(mapStateToProps)(QuestionList);
