import React, { useEffect, useState } from "react";
import { Chart } from "chart.js";

const AnalysisOfPrevious = (props) => {
  const [TblData, SetTblData] = useState({});
  useEffect(() => {
    let data = props.AdvReportData.previous_test_comparison.tests;
    calculateExtraTimeTaken(data);
    SetTblData(data);
    previousAndPresentTestAnalysis();
  }, []);
  const previousAndPresentTestAnalysis = () => {
    let yLabels = [];
    let xLabels = [];
    let marksObtained = [];
    calculateExtraTimeTaken(props.AdvReportData.previous_test_comparison.tests);
    props.AdvReportData.previous_test_comparison.tests.forEach((key, value) => {
      let test_name = "";
      if (key.test_name.length > 10) {
        test_name = key.test_name.substring(0, 10) + "...";
      } else {
        test_name = key.test_name;
      }
      yLabels.push(key.percentage);
      xLabels.push(test_name);
      marksObtained.push(
        key.marks_obtained + " / " + parseInt(key.maximum_marks)
      );
    });
    var id = "previousPresentTestAnalysis";
    var canvas = document?.getElementById(id);
    var ctx = canvas?.getContext("2d");
    var myLine = new Chart(ctx, {
      type: "line",
      data: {
        labels: xLabels,
        datasets: [
          {
            backgroundColor: "rgb(255, 99, 132)",
            borderColor: "rgb(255, 99, 132)",
            data: yLabels,
            fill: false,
            label: "Analysis of Previous and Present tests",
          },
        ],
      },
      options: {
        title: {
          display: true,
          text: "Analysis of Previous and Present tests",
        },
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          mode: "label",
          callbacks: {
            title: function (t, d) {
              return d.labels[t[0].label];
            },
            beforeFooter: function (tooltipItems, value) {
              for (var i = 0; i < marksObtained.length; i++) {
                return marksObtained[tooltipItems[0].index];
              }
            },
            footer: function (tooltipItems, data) {
              return "Marks Obtained";
            },
          },
        },
        // scales: {
        //   xAxes: [{
        //     display: true,
        //     scaleLabel: {
        //       display: true,
        //       labelString: 'Tests'
        //     }
        //   }],
        //   yAxes: [{
        //     display: true,
        //     scaleLabel: {
        //       display: true,
        //       labelString: 'Percentage'
        //     },
        //     ticks: {
        //       beginAtZero: true,
        //     }
        //   }]
        // },
      },
    });
    //})
  };

  const calculateExtraTimeTaken = (tests) => {
    tests.forEach((key, val) => {
      key.diff = key.total_alloted_time - key.total_time_taken;
      key.absDiff = Math.abs(key.diff);
      if (key.absDiff > 60) {
        if (Math.floor(key.absDiff / 3600) > 0) {
          key.formattedTimeTaken =
            Math.floor(key.absDiff / 3600) +
            "h " +
            Math.floor((key.absDiff % 3600) / 60) +
            "m " +
            Math.floor((key.absDiff % 3600) % 60) +
            "s ";
        } else {
          key.formattedTimeTaken =
            Math.floor((key.absDiff % 3600) / 60) +
            "m " +
            Math.floor((key.absDiff % 3600) % 60) +
            "s ";
        }
      } else {
        key.formattedTimeTaken = key.absDiff + " s";
      }
      if (key.diff >= 0) {
        key.timeSaved = true;
        key.extraTimeTaken = false;
      } else {
        key.timeSaved = false;
        key.extraTimeTaken = true;
      }
    });
  };

  return (
    <>
      <div className="row m-t-2e ">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 border-b-1">
          <h3>Analysis of Previous and Present tests</h3>
        </div>
        {props.AdvReportData.previous_test_comparison.tests.length == 0 && (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex m-2e">
            <h5 className="m-auto">Previous Tests History is Not Available</h5>
          </div>
        )}
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  m-t-2e p-b-2e">
          {/* <canvas id="previousPresentTestAnalysis"  height="150"></canvas> */}
        </div>
      </div>
      <table className="table table-responsive" style={{ width: "100%" }}>
        <thead>
          <tr style={{ textAlign: "center" }}>
            <th className="border">Test Name</th>
            <th className="border">Questions Attempted</th>
            <th className="border">Marks Obtained</th>
            <th className="border">Percentage</th>
            <th className="border">
              <span style={{ paddingRight: "10px" }}>Time Saved</span>
            </th>
          </tr>
          {props.AdvReportData.previous_test_comparison.tests &&
            props.AdvReportData.previous_test_comparison.tests.map(
              (test, index) => (
                <tr>
                  <td className="border">{test.test_name}</td>
                  <td className="border" style={{ textAlign: "center" }}>
                    {test.total_attempted}
                  </td>
                  <td className="border" style={{ textAlign: "center" }}>
                    {test.marks_obtained}
                  </td>
                  <td className="border" style={{ textAlign: "center" }}>
                    {test.percentage > 0 && <span>{test.percentage}</span>}{" "}
                    {1 > test.percentage && <span>0.00</span>}
                  </td>
                  <td
                    className="border"
                    style={{
                      color: test.diff >= 0 ? "darkgreen" : "red",
                      textAlign: "center",
                    }}
                  >
                    {test.diff >= 0 ? test.formattedTimeTaken : "NA"}
                  </td>
                </tr>
              )
            )}
        </thead>
      </table>
    </>
  );
};
export default AnalysisOfPrevious;
